import React from 'react'
import SVG from 'react-inlinesvg'

const apiHost = process.env.ALERT_HOST.replace(/\/$/, '')

export class ExceptionHandler extends React.Component {
  sent: boolean
  state: { hasError: boolean }

  constructor(props) {
    super(props)
    this.sent = false
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // if (process.env.NODE_ENV !== 'development') {
    //   setTimeout(() => {
    //     window.location = '/'
    //   }, 5000)
    // }
    return { hasError: true }
  }

  async componentDidCatch(error, errorInfo) {
    if (!this.sent) {
      this.sent = true
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        },
        method: 'POST',
        body: JSON.stringify({
          subject: '⚠️ Heylink Outbound frontend error',
          message: error.toString() + '\n\n' + JSON.stringify(errorInfo),
          page: window.location.href,
        }),
      }
      await window.fetch(`${apiHost}/connect/v1/alert`, options)
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <main className="p-0 m-0">
          <div className="main-bg">
            <SVG src="/images/background-left.svg" />
          </div>
          <div className="exception-container text-center">
            <SVG src="/images/error-illustration.svg" />
            <div className="text-dark text-boldest text-largestest m-t-8">
              Oops! Something happened
            </div>
            <div className="text-light text-bold text-bigger m-t-4">
              An error has occurred. We're notified of the issue and working
              hard on fixing it.
            </div>
            <button
              className="btn btn-dark text-bolder text-big m-t-80"
              onClick={() => {
                window.location.href = '/'
              }}
            >
              Go to dashboard
            </button>
          </div>
        </main>
      )
    } else {
      return this.props.children
    }
  }
}
