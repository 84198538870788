import { useState, useCallback } from 'react'

export const useFormSubmit = (callback: any): [any, boolean] => {
  const [submitting, setSubmitting] = useState(false)
  const onSubmit = useCallback(
    async (values) => {
      setSubmitting(true)
      try {
        await callback(values)
      } catch (error) {
        console.error('ERROR useFormSubmit', error)
      }
      setSubmitting(false)
    },
    [callback]
  )

  return [onSubmit, submitting]
}
