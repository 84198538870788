import { useState, useEffect } from 'react'

export const useInterval = (timeout: number) => {
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((i) => i + 1)
    }, timeout)
    return () => clearInterval(interval)
  }, [timeout])

  return counter
}
