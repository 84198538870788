import { useState, useLayoutEffect } from 'react'
import useIsMounted from 'ismounted'

export const useIsOverflowY = (ref, callback, reset) => {
  const [isOverflow, setIsOverflow] = useState(false)
  const isMounted = useIsMounted()

  useLayoutEffect(() => {
    const { current } = ref

    const trigger = () => {
      try {
        if (current && isMounted) {
          const hasOverflow = current.scrollHeight > current.clientHeight
          if (isOverflow !== hasOverflow) {
            setIsOverflow(hasOverflow)
            if (callback) callback(hasOverflow)
          }
        }
      } catch (e) {
        // console.log(e)
      }
    }

    if (current && isMounted) {
      trigger()
      // setTimeout(trigger, 0)
    }
  }, [ref, callback, ...(reset || [])])

  return isOverflow
}

export const useIsOverflow = useIsOverflowY

export const useIsOverflowX = (ref, callback, reset) => {
  const [isOverflow, setIsOverflow] = useState(false)
  const isMounted = useIsMounted()

  useLayoutEffect(() => {
    const { current } = ref

    const trigger = () => {
      try {
        if (current && isMounted) {
          const hasOverflow = current.scrollWidth > current.clientWidth
          if (isOverflow !== hasOverflow) {
            setIsOverflow(hasOverflow)
            if (callback) callback(hasOverflow)
          }
        }
      } catch (e) {
        // console.log(e)
      }
    }

    if (current && isMounted) {
      trigger()
      // setTimeout(trigger, 0)
    }
  }, [ref, callback, ...(reset || [])])

  return isOverflow
}
