import { useState, useEffect, useContext } from 'react'
import { modalContext } from '../contexts'
import { TTimeout } from '../types'

type TModal = {
  modalOpen: boolean
  modalContent: any
  modalOptions: any
  showModal: (content: any, options?: any) => void
  hideModal: () => void
}
export const useModal = (): TModal => useContext(modalContext) as TModal

let modalClearTimeout: TTimeout | undefined = undefined

export const useModalContext = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<any>(undefined)
  const [modalOptions, setModalOptions] = useState({})

  const showModal = (newContent, newOptions = undefined) => {
    setModalOpen(newContent !== undefined)
    if (newOptions) {
      setModalOptions(newOptions)
    } else if (!modalOpen) {
      setModalOptions({})
    }
    if (newContent) {
      setModalContent(newContent)

      if (modalClearTimeout) {
        clearTimeout(modalClearTimeout)
        modalClearTimeout = undefined
      }
    } else {
      modalClearTimeout = setTimeout(() => {
        setModalContent(null)
        modalClearTimeout = undefined
      }, 500)
    }
  }

  const hideModal = () => showModal(undefined)

  const path = window.location.pathname
  useEffect(() => {
    if (modalOpen) {
      showModal(undefined)
    }
  }, [path])

  return { modalOpen, modalContent, modalOptions, showModal, hideModal }
}
