const connectHost = process.env.CONNECT_HOST!.replace(/\/$/, '')
const connectPathPrefix = '/connect/v1/outbound'

const connectApiKey = process.env.CONNECT_API_KEY!

const connectFetch = (path: string, options?: any) => {
  options = options || {}
  options.method = options.method || 'GET'
  options.headers = {
    'Authorization': `Basic ${connectApiKey}`,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  // options.mode = 'no-cors'

  return window
    .fetch(`${connectHost}${connectPathPrefix}${path}`, options)
    .then(async (raw) => {
      const data = await raw.json()
      return data
    })
    .catch((error) => {
      console.error(`${path}:`, error)
      return {}
    })
}

export const connectGet = (path: string, params?: any) => {
  if (params) {
    const divider = path.includes('?') ? '&' : '?'
    const fullPath = `${path}${divider}${new URLSearchParams(
      params
    ).toString()}`
    return connectFetch(fullPath)
  } else {
    return connectFetch(path)
  }
}

export const connectPost = (path: string, body: any) =>
  connectFetch(path, {
    method: 'POST',
    body: JSON.stringify(body),
  })

export const connectPut = (path: string, body: any) =>
  connectFetch(path, {
    method: 'PUT',
    body: JSON.stringify(body),
  })

export const connectDelete = (path: string, body?: any) =>
  connectFetch(path, {
    method: 'DELETE',
  })

export const connectPatch = (path: string, body?: any) =>
  connectFetch(path, {
    method: 'PATCH',
  })
