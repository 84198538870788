import { useState, useEffect } from 'react'
import { format } from 'date-fns'
import useIsMounted from 'ismounted'
import { isBlank } from '../helpers'
import { memoGet, memoSet } from '../helpers/memostorage'
import { getRequest, postRequest } from '../../../services/api'

const normalizeParams = (params: any) => {
  if (typeof params !== 'object') return params

  if (params.fromDate) {
    params.fromDate = format(params.fromDate, 'yyyy-MM-dd')
  }
  if (params.toDate) {
    params.toDate = format(params.toDate, 'yyyy-MM-dd')
  }
  if (params.fromToDate) {
    params.fromDate = format(params.fromToDate, 'yyyy-MM-dd')
    params.toDate = format(params.fromToDate, 'yyyy-MM-dd')
    delete params.fromToDate
  }

  return params
}

export const useApiGet = <T>(
  path: string,
  params?: any,
  deps?: any[]
): T | null => {
  const [requesting, setRequesting] = useState(false)
  const [response, setResponse] = useState<T | null>(null)
  const isMounted = useIsMounted()

  useEffect(() => {
    setRequesting(false)
    setResponse(null)
  }, [...(deps || [])])

  useEffect(() => {
    const callback = async () => {
      if (requesting === false && response === null) {
        setRequesting(true)

        const memoResponse = memoGet(path, params)
        if (memoResponse && !isBlank(memoResponse)) {
          setResponse(memoResponse as T)
        }

        const newResponse = await getRequest(path, normalizeParams(params))
        if (isMounted.current) {
          if (newResponse && newResponse.data && !isBlank(newResponse.data)) {
            setResponse(newResponse.data)
            memoSet(path, params, newResponse.data)
          } else {
            setResponse({} as T)
          }
        }
      }
    }
    callback()
  }, [requesting, response])

  return response
}

export const useApiPost = <T>(
  path: string,
  params?: any,
  deps?: any[]
): T | null => {
  const [requesting, setRequesting] = useState(false)
  const [response, setResponse] = useState<T | null>(null)
  const isMounted = useIsMounted()

  useEffect(() => {
    const callback = async () => {
      if (requesting === false && response === null) {
        setRequesting(true)
        const newResponse = await postRequest(path, normalizeParams(params))
        if (isMounted.current) {
          if (newResponse && newResponse.data && !isBlank(newResponse.data)) {
            setResponse(newResponse.data as T)
          } else {
            setResponse({} as T)
          }
        }
      }
    }
    callback()
  }, [requesting, response])

  useEffect(() => {
    setRequesting(false)
    setResponse(null)
  }, [...(deps || [])])

  return response
}

export const useApiMore = <T>(
  path: string,
  paramsCallback: (offset: number) => any,
  options: {
    perPage: number
    resetDeps?: any[]
    dataKey?: string
    totalKey?: string
  }
): {
  data: T
  total: number
  loading: boolean
  loaded: boolean
  hasMore: boolean
  loadMore: () => void
} => {
  const [lastResult, setLastResult] = useState('')
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [hasMore, setHasMore] = useState(false)

  const isMounted = useIsMounted()

  const offset = page * options.perPage
  const params = paramsCallback(offset)

  const nextData = useApiPost<any>(path, params, [
    ...(options.resetDeps || []),
    offset,
  ])

  useEffect(() => {
    if (isMounted.current) {
      setLastResult('')
      setData([] as any)
      setLoading(true)
      // setLoaded(false)
      setPage(0)
      setHasMore(false)
    }
  }, options.resetDeps || [true])

  useEffect(() => {
    if (isMounted.current) {
      if (nextData === null) {
        return
      }
      if (nextData !== null) {
        setLoaded(true)
      }
      const newData =
        nextData && (options.dataKey ? nextData[options.dataKey] : nextData)
      if (!isBlank(newData) && Array.isArray(newData)) {
        const lastNextRecord = JSON.stringify(newData[newData.length - 1])
        setLoading(false)
        if (lastNextRecord !== lastResult) {
          setLastResult(lastNextRecord)
          setData([...data, ...newData] as any)
          setHasMore(newData.length >= options.perPage)
        }
        if (options.totalKey) {
          setTotal(nextData[options.totalKey])
        }
      } else {
        setLoading(false)
        setHasMore(false)
      }
    }
  }, [nextData])

  const loadMore = () => {
    setLoading(true)
    setPage(page + 1)
  }

  return {
    data,
    total,
    loading,
    loaded,
    hasMore,
    loadMore,
  }
}

export const useApiPagination = <T>(
  path: string,
  paramsCallback: (offset: number) => any,
  options: {
    perPage: number
    resetDeps?: any[]
    dataKey?: string
    totalKey?: string
  }
): {
  data: T
  total: number
  loading: boolean
  loaded: boolean
  hasMore: boolean
  loadMore: () => void
} => {
  const [lastResult, setLastResult] = useState('')
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [hasMore, setHasMore] = useState(false)

  const isMounted = useIsMounted()

  const offset = page * options.perPage
  const params = paramsCallback(offset)

  const nextData: any = useApiPost(path, params, [
    ...(options.resetDeps || []),
    offset,
  ])

  useEffect(() => {
    if (isMounted.current) {
      setLastResult('')
      setData([] as any)
      setLoading(true)
      // setLoaded(false)
      setPage(0)
      setHasMore(false)
    }
  }, options.resetDeps || [true])

  useEffect(() => {
    if (isMounted.current) {
      if (nextData === null) {
        return
      }
      if (nextData !== null) {
        setLoaded(true)
      }
      const newData =
        nextData && (options.dataKey ? nextData[options.dataKey] : nextData)
      if (!isBlank(newData) && Array.isArray(newData)) {
        const lastNextRecord = JSON.stringify(newData[newData.length - 1])
        setLoading(false)
        if (lastNextRecord !== lastResult) {
          setLastResult(lastNextRecord)
          setData([...newData] as any)
          setHasMore(newData.length >= options.perPage)
        }
        if (options.totalKey) {
          setTotal(nextData[options.totalKey])
        }
      } else {
        setLoading(false)
        setHasMore(false)
      }
    }
  }, [nextData])

  const loadMore = () => {
    setLoading(true)
    setPage(page + 1)
  }

  return {
    data,
    total,
    loading,
    loaded,
    hasMore,
    loadMore,
  }
}
