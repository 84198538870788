import React from 'react'
import { countries, countryPhoneCodes } from '../helpers/constants'

const countryCodes = Object.keys(countries)

export const CountryPhoneCodeSelectOptions = (props) => {
  return (
    <>
      {countryCodes.map((countryCode) => {
        if (countryPhoneCodes[countryCode]) {
          return (
            <option key={countryCode} value={countryPhoneCodes[countryCode]}>
              +{countryPhoneCodes[countryCode]}
            </option>
          )
        } else {
          return null
        }
      })}
    </>
  )
}
