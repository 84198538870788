import React, { forwardRef } from 'react'
import moment from 'moment'
import SVG from 'react-inlinesvg'
import DatePicker from 'react-datepicker'
import { varClass } from '../helpers'

const today = moment().format('YYYY-MM-DD')

const formatValue = (value: string, blank = 'No date'): string => {
  if (value === today) return 'Today'
  if (!value) return blank
  return value
}

export const DateInput = (props) => {
  const { blankValue } = props

  const Input = forwardRef((props: any, ref: any) => (
    <>
      <input
        {...props}
        ref={ref}
        value={formatValue(props.value, blankValue)}
        className={varClass({
          'text-light text-normal': !props.value,
        })}
        readOnly
      />
      <SVG src="/images/icon-calendar.svg" className="prefix" />
    </>
  ))

  return (
    <DatePicker customInput={<Input />} dateFormat="yyyy-MM-dd" {...props} />
  )
}
