import { useState } from 'react'

export const useArray = <T>(initialValue: T[] = []) => {
  const [values, setValues] = useState(initialValue || [])

  const add = (valueToAdd: T) => {
    const newValues = [...values]
    if (newValues.indexOf(valueToAdd) === -1) {
      newValues.push(valueToAdd)
      setValues(newValues)
    }
  }

  const addAll = (valuesToAdd: T[]) => {
    const newValues = [...values]
    for (const valueToAdd of valuesToAdd) {
      if (newValues.indexOf(valueToAdd) === -1) {
        newValues.push(valueToAdd)
      }
    }
    setValues(newValues)
  }

  const remove = (valueToRemove: T) => {
    const newValues = [...values]
    const index = values.indexOf(valueToRemove)
    if (index !== -1) {
      newValues.splice(index, 1)
      setValues(newValues)
    }
  }

  const removeAll = (valuesToRemove: T[]) => {
    const newValues = [...values]
    for (const valueToRemove of valuesToRemove) {
      const index = newValues.indexOf(valueToRemove)
      if (index !== -1) {
        newValues.splice(index, 1)
      }
    }
    setValues(newValues)
  }

  const toggle = (valueToToggle: T) => {
    const newValues = [...values]
    const index = values.indexOf(valueToToggle)
    if (index === -1) {
      newValues.push(valueToToggle)
    } else {
      newValues.splice(index, 1)
    }
    setValues(newValues)
  }

  const set = (valuesToSet: T[]) => {
    setValues(valuesToSet)
  }

  const clear = () => {
    setValues([])
  }

  return { values, add, addAll, remove, removeAll, toggle, set, clear }
}
