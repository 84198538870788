import React from 'react'
import { varClass } from '..'

export const Commission = (props) => {
  const { type, value, className } = props

  return (
    <div
      className={varClass({
        'commission-value vertical-middle': true,
        [className]: !!className,
      })}
    >
      {type && type !== 'Unknown' && type !== 'NoCommission' ? (
        <span className={`tag tag-${(type || '').toLowerCase()}`}>{type}</span>
      ) : (
        <span className="p-x-05" />
      )}
      <span>{value}</span>
    </div>
  )
}
