import { useState, useEffect } from 'react'

export const useMemoPresent = (data) => {
  const [memo, setMemo] = useState(undefined)

  useEffect(() => {
    if (data) {
      setMemo(data)
    }
  }, [data])

  return memo
}
