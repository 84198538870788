import React from 'react'
import { isInvalidDate } from '../helpers/fns'
import { shortMonths } from '../helpers/constants'

export const formatShortDatetime = (str: string) => {
  if (isInvalidDate(str)) return ''

  const [date, time] = str.split(' ')
  const [year, month, day] = date.split('-')

  return (
    <>
      <span className="text-light">
        {day} {shortMonths[month]} {year}
      </span>
      <span className="m-l-2">{time.replace(/:\d\d$/, '')}</span>
    </>
  )
}
