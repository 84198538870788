import React from 'react'
import { isBlank, SmallLoadable } from '..'

export const AssigneeList = (props) => {
  try {
    const { domain, assignees } = props
    if (isBlank(assignees)) return null

    const shownAssignees =
      assignees?.filter(
        (assignee) => assignee.assigneeAdvertiserDomain === domain
      ) || []
    if (!shownAssignees?.length) return null

    return (
      <SmallLoadable data={assignees}>
        <div className="row row-narrow row-nowrap">
          {shownAssignees.map((assignee) => (
            <div
              key={assignee.assigneeUserUuid}
              className="badge text-smaller text-bold"
              title={`${assignee.assigneeUserFirstname} ${assignee.assigneeUserLastname}`}
            >
              {assignee.assigneeUserFirstname}{' '}
              {assignee.assigneeUserLastname[0]}.
            </div>
          ))}
        </div>
      </SmallLoadable>
    )
  } catch (error) {
    console.error(error)
    return null
  }
}
