import React from 'react'
import SVG from 'react-inlinesvg'
import { varClass } from '../helpers'

export const NoData = (props) => {
  const { title, description, className } = props

  return (
    <tr>
      <td
        colSpan={42}
        className={varClass({ 'text-center': true, [className]: !!className })}
      >
        <SVG src="/images/icon-nodata.svg" />
        <div className="text-dark text-bolder m-t-3">{title || 'No data'}</div>
        {description && <div className="m-t-1">{description}</div>}
      </td>
    </tr>
  )
}

export const NoDataDiv = (props) => {
  const { title, description, className } = props

  return (
    <div
      className={varClass({ 'text-center': true, [className]: !!className })}
    >
      <SVG src="/images/icon-nodata.svg" />
      <div className="text-dark text-bolder m-t-3">{title || 'No data'}</div>
      {description && <div className="m-t-1">{description}</div>}
    </div>
  )
}
