import React from 'react'
import SVG from 'react-inlinesvg'
import { uniq } from 'lodash'
import { varClass } from '../helpers'

export const Pagination = (props) => {
  const { page, setPage, maxPage } = props

  const gotoPage = (newPage) => () => {
    if (newPage !== page && newPage >= 1 && newPage <= maxPage) {
      setPage(newPage)
    }
  }

  if (maxPage <= 1) {
    return null
  }

  const pages = calculatePages(page, maxPage)

  return (
    <div className="pagination">
      <button className="pagination-link" onClick={gotoPage(page - 1)}>
        <SVG src="/images/chevron-right.svg" className="rotate-180" />
      </button>

      {pages.map((i, index) =>
        i === -1 ? (
          <div key={index} className="pagination-gap">
            ...
          </div>
        ) : (
          <button
            key={index}
            className={varClass({
              'pagination-link': true,
              'active': page === i,
            })}
            onClick={gotoPage(i)}
          >
            {i}
          </button>
        )
      )}

      <button className="pagination-link" onClick={gotoPage(page + 1)}>
        <SVG src="/images/chevron-right.svg" />
      </button>

      {props.children}
    </div>
  )
}

const calculatePages = (current: number, max: number) => {
  const result = [1, max]
  for (let i = current - 2; i <= current + 2; i++) {
    if (i > 1 && i < max) {
      result.push(i)
    }
  }
  const sortedResult = uniq(result.sort((a: number, b: number) => a - b))
  if (sortedResult[1] - sortedResult[0] > 1) {
    sortedResult.splice(1, 0, -1)
  }
  if (
    sortedResult[sortedResult.length - 1] -
      sortedResult[sortedResult.length - 2] >
    1
  ) {
    sortedResult.splice(sortedResult.length - 1, 0, -1)
  }
  return sortedResult
}
