import React, { useState, useMemo } from 'react'
import SVG from 'react-inlinesvg'
import { toUnicode } from 'punycode'
import {
  SmallLoadable,
  WithTooltip,
  Logo,
  Commission,
  useApiGet,
  capitalize,
  formatCommission,
  formatStat,
  formatCurrency,
  toUrl,
  varClass,
  map,
  isBlank,
  isValidDate,
  isInvalidDate,
  inTimezone,
  copyToClipboard,
} from '..'
import { IInsightsV1Click, IInsightsV1Conversion } from '../types'

const detail = (value: string) => {
  if (!value) {
    return (
      <span className="text-light text-bold text-italic p-r-1">Undefined</span>
    )
  }
  if (isInvalidDate(value)) {
    return <span className="text-light text-bold text-italic">-</span>
  }
  return <span className="text-bold">{value}</span>
}

const detailName = (value: string): string => {
  if (!value) {
    return ''
  } else if (value === 'heylink.com') {
    return 'Test conversion'
  } else {
    return value
  }
}

const detailPath = (value: string) => {
  if (!value)
    return (
      <span className="text-light text-bold text-italic p-r-1">Undefined</span>
    )

  const splitIndex =
    value.indexOf('/') !== -1 ? value.indexOf('/') : value.length

  const host = value.substr(0, splitIndex)
  const path = value.substr(splitIndex)

  return (
    <>
      <span className="text-dark">{toUnicode(host)}</span>
      {path && <span className="text-light o-50">{path}</span>}
    </>
  )
}

export const ClickDrawer = (props) => {
  const { clickUuid, networks, showSnackbar } = props

  const details = useApiGet<IInsightsV1Click>(
    `/insights/v1/clicks/${clickUuid}`,
    {},
    [clickUuid]
  )

  const network = useMemo(
    () =>
      details?.network?.networkUuid
        ? networks.find(
            (network) => network.networkUuid === details.network.networkUuid
          )
        : null,
    [details]
  )
  const [tab, setTab] = useState('timeline')

  if (!details) {
    return <SmallLoadable height={90} />
  }

  if (isBlank(details)) {
    return (
      <>
        <div className="card-header">
          <div className="text-dark text-big text-bolder">Click details</div>
        </div>
        <div className="card-body p-t-30">
          <div className="text-center text-light text-bolder">
            No info found
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="card-header">
        <div className="text-dark text-big text-bolder">Click details</div>
      </div>
      <div className="card-body p-t-30">
        <div className="text-dark text-large text-bolder m-b-8">
          {detailName(
            details.clickName || details.advertiser.advertiserDomain
          ) || (
            <>
              <span className="text-light text-italic">Undefined</span>
              <SVG
                src="/images/insights/icon-undefined.svg"
                className="text-new-orange m-l-10"
              />
            </>
          )}
        </div>
        <div className="row row-space-between">
          <div className="flex-1-3">
            <div className="text-light text-small text-bold m-b-15">
              Click date/time:
            </div>
            <div className="text-small text-bold">
              {inTimezone(details.clickDatetime)}
            </div>
          </div>
          <div className="flex-1-3">
            <div className="text-light text-small text-bold m-b-15">
              From device:
            </div>
            <div className="text-small text-bold">
              {detail(details.clickDeviceType)}
            </div>
          </div>
          <div className="flex-1-3">
            {/*
            <div className="text-light text-small text-bold m-b-15">
              Current status:
            </div>
            <ConversionStatusTag status={details.conversionStatusKey} />
            */}
          </div>
          {/*
          <div className="flex-1-3">
            <div className="text-light text-small text-bold m-b-15">
              Short code used:
            </div>
            <div className="text-small text-bold">
              {detail(details.conversionUrlShortCode)}
            </div>
          </div>
          */}
        </div>

        <div className="conversion-tab-select m-t-50 m-b-6">
          {map(
            {
              'timeline': 'Timeline',
              'network': 'Network',
              'tracker': 'Tracking ID’s',
            },
            (value, label) => (
              <button
                key={value}
                className={varClass({
                  'link': true,
                  'active': tab === value,
                })}
                onClick={() => setTab(value)}
              >
                {label}
              </button>
            )
          )}
        </div>

        {tab === 'timeline' && (
          <div className="timeline-container">
            <div className="timeline with-date">
              <SVG src="/images/insights/tl-visit.svg" />
              <div>
                <div className="timeline-title">User visited</div>
                <div className="timeline-value">
                  <WithTooltip
                    label={details.clickUrlPath}
                    text={detailPath(details.clickUrlPath)}
                  />
                  {details.clickUrlPath && (
                    <div>
                      <button
                        className="link"
                        onClick={() => {
                          copyToClipboard(details.clickUrlPath)
                          showSnackbar('Copied to clipboard')
                        }}
                      >
                        <SVG src="/images/icon-copy.svg" />
                      </button>
                      <button
                        className="link"
                        onClick={() => {
                          window.open(toUrl(details.clickUrlPath), '_blank')
                        }}
                      >
                        <SVG src="/images/icon-outside-btn.svg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="timeline-date">
                  {inTimezone(details.clickDatetime)}
                </div>
              </div>
            </div>
            <div
              className={varClass({
                'timeline': true,
                'timeline-short': !details.clickTargetUrlPath,
              })}
            >
              <SVG src="/images/insights/tl-click.svg" />
              <div>
                <div className="timeline-title">User clicked a link to</div>
                <div className="timeline-value">
                  <WithTooltip
                    label={details.clickTargetUrlPath}
                    text={detailPath(details.clickTargetUrlPath)}
                  />
                  {details.clickTargetUrlPath && (
                    <div>
                      <button
                        className="link"
                        onClick={() => {
                          copyToClipboard(details.clickTargetUrlPath)
                          showSnackbar('Copied to clipboard')
                        }}
                      >
                        <SVG src="/images/icon-copy.svg" />
                      </button>
                      <button
                        className="link"
                        onClick={() => {
                          window.open(
                            toUrl(details.clickTargetUrlPath),
                            '_blank'
                          )
                        }}
                      >
                        <SVG src="/images/icon-outside-btn.svg" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="timeline">
              <SVG src="/images/insights/tl-network.svg" />
              <div>
                <div className="timeline-title">Network</div>
                <div className="timeline-value">
                  <div>{detail(details.network.networkName)}</div>
                  {details.network.networkUrl && (
                    <div>
                      <button
                        className="link"
                        onClick={() => {
                          window.open(
                            toUrl(details.network.networkUrl),
                            '_blank'
                          )
                        }}
                      >
                        <SVG src="/images/icon-outside-btn.svg" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="timeline">
              <SVG src="/images/insights/tl-network.svg" />
              <div>
                <div className="timeline-title">Campaign</div>
                <div className="timeline-value">
                  {detail(details.tracker.trackerName)}
                </div>
              </div>
            </div>
            {map(details?.conversions, (conversion, index) => (
              <React.Fragment key={(conversion.conversionUuid, index)}>
                {isValidDate(conversion?.conversionCreatedDatetime) && (
                  <div className="timeline with-date">
                    <SVG src="/images/insights/tl-conversion.svg" />
                    <div>
                      <div className="timeline-title">Conversion created</div>
                      <div className="timeline-value">
                        Sale on{' '}
                        {formatStat(
                          conversion.conversionSale,
                          conversion.conversionCurrencyCode || 'DKK'
                        )}
                      </div>
                      <div className="timeline-date">
                        {detail(
                          inTimezone(conversion.conversionCreatedDatetime)
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!!(
                  isValidDate(conversion?.conversionCreatedDatetime) &&
                  isInvalidDate(conversion?.conversionRejectedDatetime) &&
                  isInvalidDate(conversion?.conversionPaidDatetime) &&
                  conversion?.conversionCommission
                ) && (
                  <div className="timeline">
                    <SVG src="/images/insights/tl-commission.svg" />
                    <div>
                      <div className="timeline-title">Commission from sale</div>
                      <div className="timeline-value">
                        Revenue earned{' '}
                        {formatStat(
                          conversion.conversionCommission,
                          conversion.conversionCurrencyCode || 'DKK'
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {isValidDate(conversion?.conversionRejectedDatetime) && (
                  <div className="timeline with-date">
                    <SVG src="/images/insights/tl-rejected.svg" />
                    <div>
                      <div className="timeline-title">Conversion rejected</div>
                      <div className="timeline-value">
                        Amount{' '}
                        {formatStat(
                          conversion.conversionSale,
                          conversion.conversionCurrencyCode || 'DKK'
                        )}
                      </div>
                      <div className="timeline-date">
                        {detail(
                          inTimezone(conversion.conversionRejectedDatetime)
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {isValidDate(conversion.conversionPaidDatetime) && (
                  <div className="timeline with-date">
                    <SVG src="/images/insights/tl-approved.svg" />
                    <div>
                      <div className="timeline-title">Conversion approved</div>
                      <div className="timeline-value">
                        Revenue earned{' '}
                        {formatStat(
                          conversion.conversionCommission,
                          conversion.conversionCurrencyCode || 'DKK'
                        )}
                      </div>
                      <div className="timeline-date">
                        {detail(inTimezone(conversion.conversionPaidDatetime))}
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}

        {tab === 'network' && (
          <div className="network-container">
            {network.networkLogoUrl && (
              <Logo
                src={network.networkLogoUrl}
                width={106}
                height={30}
                className="m-b-10"
              />
            )}
            <div className="row row-center row-space-between">
              <div className="text-network-name">{network.networkName}</div>
              <div className="text-right">
                <button
                  className="link"
                  onClick={() => {
                    copyToClipboard(
                      details.network.networkUrl || network.networkName
                    )
                    showSnackbar('Copied to clipboard')
                  }}
                >
                  <SVG src="/images/icon-copy.svg" />
                </button>
                {details.network.networkUrl && (
                  <button
                    className="link"
                    onClick={() => {
                      window.open(toUrl(details.network.networkUrl), '_blank')
                    }}
                  >
                    <SVG src="/images/icon-outside-btn.svg" />
                  </button>
                )}
              </div>
            </div>

            <hr className="m-y-4" />

            <div className="row row-space-between">
              <div className="flex-1-2">
                <div className="text-light m-b-1">Account ID:</div>
                <div className="text-dark text-bold">
                  {detail(details.connection.connectionPartnerId)}
                </div>
              </div>
              <div className="flex-1-2">
                <div className="text-light m-b-1">Commission:</div>
                <div>
                  <Commission
                    type={details.tracker.trackerCommissionType}
                    value={formatCommission(
                      details.tracker.trackerCommissionType,
                      details.tracker.trackerCommissionFlat,
                      details.tracker.trackerCommissionPercentage,
                      details.tracker.trackerCurrencyCode || 'DKK'
                    )}
                  />
                </div>
              </div>
              <div className="flex-1-2">
                <div className="text-light m-b-1">Campaign:</div>
                <div className="text-dark text-bold">
                  {detail(details.tracker.trackerName)}
                </div>
              </div>
              {/*
              <div className="flex-1-2">
                <div className="text-light m-b-1">Campaign ID:</div>
                <div className="text-dark text-bold">{detail('')}</div>
              </div>
              */}
            </div>
          </div>
        )}

        {tab === 'tracker' && (
          <div className="tracker-container p-b-0">
            <div className="m-b-30">
              <div className="text-light m-b-1">Click ID:</div>
              <div className="row row-space-between">
                <div className="text-dark text-bold">
                  {detail(details.clickUuid)}
                </div>
                {details.clickUuid && (
                  <button
                    className="link"
                    onClick={() => {
                      copyToClipboard(details.clickUuid)
                      showSnackbar('Copied to clipboard')
                    }}
                  >
                    <SVG src="/images/icon-copy.svg" />
                  </button>
                )}
              </div>
            </div>

            {!!details?.conversions?.length && (
              <>
                <div className="m-b-30">
                  <div className="text-light m-b-1">
                    Conversion ID{details.conversions.length > 1 ? 's' : ''}:
                  </div>
                  {map(details?.conversions, (conversion, index) => (
                    <div
                      key={(conversion.conversionUuid, index)}
                      className="row row-space-between"
                    >
                      <div className="text-dark text-bold">
                        {detail(conversion.conversionUuid)}
                      </div>
                      {conversion.conversionUuid && (
                        <button
                          className="link"
                          onClick={() => {
                            copyToClipboard(conversion.conversionUuid)
                            showSnackbar('Copied to clipboard')
                          }}
                        >
                          <SVG src="/images/icon-copy.svg" />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}

            {details.clickSubId && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.clickSubId)}
                  </div>
                  {details.clickSubId && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.clickSubId)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {details.clickSubId1 && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID 1:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.clickSubId1)}
                  </div>
                  {details.clickSubId1 && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.clickSubId1)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {details.clickSubId2 && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID 2:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.clickSubId2)}
                  </div>
                  {details.clickSubId2 && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.clickSubId2)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {details.clickSubId3 && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID 3:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.clickSubId3)}
                  </div>
                  {details.clickSubId3 && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.clickSubId3)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {details.clickSubId4 && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID 4:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.clickSubId4)}
                  </div>
                  {details.clickSubId4 && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.clickSubId4)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {details.clickSubId5 && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID 5:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.clickSubId5)}
                  </div>
                  {details.clickSubId5 && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.clickSubId5)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {map(details.clickTags, (tag, value) => (
              <div key={tag} className="m-b-30">
                <div className="text-light m-b-1">{tag}:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">{detail(value)}</div>
                  {value && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(value)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export const ConversionDrawer = (props) => {
  const { conversionUuid, networks, showSnackbar } = props

  const details = useApiGet<IInsightsV1Conversion>(
    `/insights/v1/conversions/${conversionUuid}`,
    {},
    [conversionUuid]
  )

  const network = useMemo(
    () =>
      details?.network?.networkUuid
        ? networks.find(
            (network) => network.networkUuid === details.network.networkUuid
          )
        : null,
    [details]
  )

  const [tab, setTab] = useState('timeline')

  if (!details) {
    return <SmallLoadable height={90} />
  }

  return (
    <>
      <div className="card-header">
        <div className="text-dark text-big text-bolder">Conversion details</div>
      </div>
      <div className="card-body p-t-30">
        <div className="text-dark text-large text-bolder m-b-8">
          {toUnicode(
            detailName(
              details.conversionName || details.advertiser.advertiserDomain
            )
          ) || (
            <>
              <span className="text-light text-italic">Undefined</span>
              <SVG
                src="/images/insights/icon-undefined.svg"
                className="text-new-orange m-l-10"
              />
            </>
          )}
        </div>
        <div className="row row-space-between">
          <div className="flex-1-3">
            <div className="text-light text-small text-bold m-b-15">
              Conversion date/time:
            </div>
            <div className="text-small text-bold">
              {inTimezone(details.conversionDatetime)}
            </div>
          </div>
          <div className="flex-1-3">
            <div className="text-light text-small text-bold m-b-15">
              From device:
            </div>
            <div className="text-small text-bold">
              {detail(details.conversionDeviceType)}
            </div>
          </div>
          <div className="flex-1-3">
            <div className="text-light text-small text-bold m-b-15">
              Current status:
            </div>
            <ConversionStatusTag status={details.conversionStatusKey} />
          </div>
          <div className="flex-1-3">
            <div className="text-light text-small text-bold m-b-15">
              Short code used:
            </div>
            <div className="text-small text-bold">
              {detail(details.conversionUrlShortCode)}
            </div>
          </div>
        </div>

        <div className="conversion-tab-select m-t-50 m-b-6">
          {map(
            {
              'timeline': 'Timeline',
              'network': 'Network',
              'tracker': 'Tracking ID’s',
            },
            (value, label) => (
              <button
                key={value}
                className={varClass({
                  'link': true,
                  'active': tab === value,
                })}
                onClick={() => setTab(value)}
              >
                {label}
              </button>
            )
          )}
        </div>

        {tab === 'timeline' && (
          <div className="timeline-container">
            {details.conversionUrlPath && (
              <div className="timeline with-date">
                <SVG src="/images/insights/tl-visit.svg" />
                <div>
                  <div className="timeline-title">User visited</div>
                  <div className="timeline-value">
                    <WithTooltip
                      label={details.conversionUrlPath}
                      text={detailPath(details.conversionUrlPath)}
                    />
                    {details.conversionUrlPath && (
                      <div>
                        <button
                          className="link"
                          onClick={() => {
                            copyToClipboard(details.conversionUrlPath)
                            showSnackbar('Copied to clipboard')
                          }}
                        >
                          <SVG src="/images/icon-copy.svg" />
                        </button>
                        <button
                          className="link"
                          onClick={() => {
                            window.open(
                              toUrl(details.conversionUrlPath),
                              '_blank'
                            )
                          }}
                        >
                          <SVG src="/images/icon-outside-btn.svg" />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="timeline-date">
                    {inTimezone(
                      details.click?.clickDatetime || details.conversionDatetime
                    )}
                  </div>
                </div>
              </div>
            )}
            <div
              className={varClass({
                'timeline': true,
                'timeline-short': !details.conversionTargetUrlPath,
              })}
            >
              <SVG src="/images/insights/tl-click.svg" />
              <div>
                <div className="timeline-title">User clicked a link to</div>
                <div className="timeline-value">
                  <WithTooltip
                    label={
                      details.conversionTargetUrlPath || details.conversionName
                    }
                    text={detailPath(
                      detailName(
                        details.conversionTargetUrlPath ||
                          details.conversionName
                      )
                    )}
                  />
                  {details.conversionTargetUrlPath && (
                    <div>
                      <button
                        className="link"
                        onClick={() => {
                          copyToClipboard(details.conversionTargetUrlPath)
                          showSnackbar('Copied to clipboard')
                        }}
                      >
                        <SVG src="/images/icon-copy.svg" />
                      </button>
                      <button
                        className="link"
                        onClick={() => {
                          window.open(
                            toUrl(details.conversionTargetUrlPath),
                            '_blank'
                          )
                        }}
                      >
                        <SVG src="/images/icon-outside-btn.svg" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="timeline">
              <SVG src="/images/insights/tl-network.svg" />
              <div>
                <div className="timeline-title">Network</div>
                <div className="timeline-value">
                  <div>{detail(details.network.networkName)}</div>
                  {details.network.networkUrl && (
                    <div>
                      <button
                        className="link"
                        onClick={() => {
                          window.open(
                            toUrl(details.network.networkUrl),
                            '_blank'
                          )
                        }}
                      >
                        <SVG src="/images/icon-outside-btn.svg" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="timeline">
              <SVG src="/images/insights/tl-network.svg" />
              <div>
                <div className="timeline-title">Campaign</div>
                <div className="timeline-value">
                  {detailName(
                    details.conversionName || details.tracker.trackerName
                  )}
                </div>
              </div>
            </div>
            {isValidDate(details.conversionCreatedDatetime) && (
              <div className="timeline with-date">
                <SVG src="/images/insights/tl-conversion.svg" />
                <div>
                  <div className="timeline-title">Conversion created</div>
                  <div className="timeline-value">
                    Sale on{' '}
                    {formatStat(
                      details.conversionSale,
                      details.conversionCurrencyCode || 'DKK'
                    )}
                  </div>
                  <div className="timeline-date">
                    {detail(inTimezone(details.conversionCreatedDatetime))}
                  </div>
                </div>
              </div>
            )}
            {!!(
              isValidDate(details.conversionCreatedDatetime) &&
              isInvalidDate(details.conversionRejectedDatetime) &&
              isInvalidDate(details.conversionPaidDatetime) &&
              details.conversionCommission
            ) && (
              <div className="timeline">
                <SVG src="/images/insights/tl-commission.svg" />
                <div>
                  <div className="timeline-title">Commission from sale</div>
                  <div className="timeline-value">
                    Revenue earned{' '}
                    {formatStat(
                      details.conversionCommission,
                      details.conversionCurrencyCode || 'DKK'
                    )}
                  </div>
                </div>
              </div>
            )}
            {isValidDate(details.conversionRejectedDatetime) && (
              <div className="timeline with-date">
                <SVG src="/images/insights/tl-rejected.svg" />
                <div>
                  <div className="timeline-title">Conversion rejected</div>
                  <div className="timeline-value">
                    Amount{' '}
                    {formatStat(
                      details.conversionSale,
                      details.conversionCurrencyCode || 'DKK'
                    )}
                  </div>
                  <div className="timeline-date">
                    {detail(inTimezone(details.conversionRejectedDatetime))}
                  </div>
                </div>
              </div>
            )}
            {isValidDate(details.conversionPaidDatetime) && (
              <div className="timeline with-date">
                <SVG src="/images/insights/tl-approved.svg" />
                <div>
                  <div className="timeline-title">Conversion approved</div>
                  <div className="timeline-value">
                    Revenue earned{' '}
                    {formatStat(
                      details.conversionCommission,
                      details.conversionCurrencyCode || 'DKK'
                    )}
                  </div>
                  <div className="timeline-date">
                    {detail(inTimezone(details.conversionPaidDatetime))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {tab === 'network' && (
          <div className="network-container">
            {network.networkLogoUrl && (
              <Logo
                src={network.networkLogoUrl}
                width={106}
                height={30}
                className="m-b-10"
              />
            )}
            <div className="row row-center row-space-between">
              <div className="text-network-name">{network.networkName}</div>
              <div className="text-right">
                <button
                  className="link"
                  onClick={() => {
                    copyToClipboard(
                      details.network.networkUrl || network.networkName
                    )
                    showSnackbar('Copied to clipboard')
                  }}
                >
                  <SVG src="/images/icon-copy.svg" />
                </button>
                {details.network.networkUrl && (
                  <button
                    className="link"
                    onClick={() => {
                      window.open(toUrl(details.network.networkUrl), '_blank')
                    }}
                  >
                    <SVG src="/images/icon-outside-btn.svg" />
                  </button>
                )}
              </div>
            </div>

            <hr className="m-y-4" />

            <div className="row row-space-between">
              <div className="flex-1-2">
                <div className="text-light m-b-1">Account ID:</div>
                <div className="text-dark text-bold">
                  {detail(details.connection.connectionPartnerId)}
                </div>
              </div>
              <div className="flex-1-2">
                <div className="text-light m-b-1">Commission:</div>
                <div>
                  <Commission
                    type={details.tracker.trackerCommissionType}
                    value={formatCommission(
                      details.tracker.trackerCommissionType,
                      details.tracker.trackerCommissionFlat,
                      details.tracker.trackerCommissionPercentage,
                      details.tracker.trackerCurrencyCode ||
                        details.conversionCurrencyCode ||
                        'DKK'
                    )}
                  />
                </div>
              </div>
              <div className="flex-1-2">
                <div className="text-light m-b-1">Campaign:</div>
                <div className="text-dark text-bold">
                  {detail(details.tracker.trackerName)}
                </div>
              </div>
              {/*
              <div className="flex-1-2">
                <div className="text-light m-b-1">Campaign ID:</div>
                <div className="text-dark text-bold">{detail('')}</div>
              </div>
              */}
            </div>
          </div>
        )}

        {tab === 'tracker' && (
          <div className="tracker-container p-b-0">
            <div className="m-b-30">
              <div className="text-light m-b-1">Conversion ID:</div>
              <div className="row row-space-between">
                <div className="text-dark text-bold">
                  {detail(details.conversionUuid)}
                </div>
                {details.conversionUuid && (
                  <button
                    className="link"
                    onClick={() => {
                      copyToClipboard(details.conversionUuid)
                      showSnackbar('Copied to clipboard')
                    }}
                  >
                    <SVG src="/images/icon-copy.svg" />
                  </button>
                )}
              </div>
            </div>

            <div className="m-b-30">
              <div className="text-light m-b-1">Merchant order ID:</div>
              <div className="row row-space-between">
                <div className="text-dark text-bold">
                  {detail(details.conversionSaleOrderId)}
                </div>
                {details.conversionSaleOrderId && (
                  <button
                    className="link"
                    onClick={() => {
                      copyToClipboard(details.conversionSaleOrderId)
                      showSnackbar('Copied to clipboard')
                    }}
                  >
                    <SVG src="/images/icon-copy.svg" />
                  </button>
                )}
              </div>
            </div>

            <div className="m-b-30">
              <div className="text-light m-b-1">Click ID:</div>
              <div className="row row-space-between">
                <div className="text-dark text-bold">
                  {detail(details.conversionClickUuid)}
                </div>
                {details.conversionClickUuid && (
                  <button
                    className="link"
                    onClick={() => {
                      copyToClipboard(details.conversionClickUuid)
                      showSnackbar('Copied to clipboard')
                    }}
                  >
                    <SVG src="/images/icon-copy.svg" />
                  </button>
                )}
              </div>
            </div>

            <div className="m-b-30">
              <div className="text-light m-b-1">Network transaction ID:</div>
              <div className="row row-space-between">
                <div className="text-dark text-bold">
                  {detail(details.conversionNetworkTransactionId)}
                </div>
                {details.conversionNetworkTransactionId && (
                  <button
                    className="link"
                    onClick={() => {
                      copyToClipboard(details.conversionNetworkTransactionId)
                      showSnackbar('Copied to clipboard')
                    }}
                  >
                    <SVG src="/images/icon-copy.svg" />
                  </button>
                )}
              </div>
            </div>

            {details.conversionSubId && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.conversionSubId)}
                  </div>
                  {details.conversionSubId && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.conversionSubId)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {details.conversionSubId1 && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID 1:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.conversionSubId1)}
                  </div>
                  {details.conversionSubId1 && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.conversionSubId1)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {details.conversionSubId2 && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID 2:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.conversionSubId2)}
                  </div>
                  {details.conversionSubId2 && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.conversionSubId2)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {details.conversionSubId3 && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID 3:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.conversionSubId3)}
                  </div>
                  {details.conversionSubId3 && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.conversionSubId3)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {details.conversionSubId4 && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID 4:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.conversionSubId4)}
                  </div>
                  {details.conversionSubId4 && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.conversionSubId4)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {details.conversionSubId5 && (
              <div className="m-b-30">
                <div className="text-light m-b-1">Sub ID 5:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">
                    {detail(details.conversionSubId5)}
                  </div>
                  {details.conversionSubId5 && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(details.conversionSubId5)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {map(details.conversionTags, (tag, value) => (
              <div key={tag} className="m-b-30">
                <div className="text-light m-b-1">{tag}:</div>
                <div className="row row-space-between">
                  <div className="text-dark text-bold">{detail(value)}</div>
                  {value && (
                    <button
                      className="link"
                      onClick={() => {
                        copyToClipboard(value)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      <SVG src="/images/icon-copy.svg" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export const ConversionStatusTag = (props) => {
  const { status } = props

  switch (status) {
    case 'pending':
      return (
        <span className="conversion-status-tag text-orange">
          <SVG src="/images/insights/status-dot.svg" />
          <span>{capitalize(status)}</span>
        </span>
      )
    case 'approved':
    case 'paid':
      return (
        <span className="conversion-status-tag text-green">
          <SVG src="/images/insights/status-check.svg" />
          <span>{capitalize(status)}</span>
        </span>
      )
    case 'rejected':
      return (
        <span className="conversion-status-tag text-red">
          <SVG src="/images/insights/status-times.svg" />
          <span>{capitalize(status)}</span>
        </span>
      )
  }

  return (
    <>
      {status === 'paid' ? (
        <div className="billing-invoice-status text-green">
          <SVG src="/images/icon-check.svg" />
          <span>{capitalize(status)}</span>
        </div>
      ) : (
        <div className="billing-invoice-status text-red">
          <SVG src="/images/icon-times.svg" />
          <span>{capitalize(status)}</span>
        </div>
      )}
    </>
  )
}

export const ConversionJourneyDrawer = (props) => {
  const { clickUuid } = props

  const journey = clickUuid
    ? useApiGet<any>(`/overview/v1/journey/${clickUuid}`, {}, [clickUuid])
    : {}

  return (
    <div className="card-body">
      <div className="h3 text-bolder m-b-8">User Journey</div>
      <SmallLoadable loaded={journey}>
        {isBlank(journey) ? (
          <div className="text-center text-light text-bolder">No data</div>
        ) : (
          <div className="overview-journey">
            <div className="journey journey-1">
              <div className="journey-icon">
                <SVG src="/images/overview/icon-journey-1.svg" />
              </div>
              <div className="journey-info">
                <div className="m-b-1">Visited</div>
                <div className="text-dark text-bolder text-breakword">
                  {toUnicode(journey.clickPageUrlPath)}
                </div>
              </div>
            </div>

            <div className="journey journey-2">
              <div className="journey-icon">
                <SVG src="/images/overview/icon-journey-1.svg" />
              </div>
              <div className="journey-info">
                <div className="m-b-1">Clicked a link to</div>
                <div className="text-dark text-bolder text-breakword m-b-1">
                  {toUnicode(journey.clickTargetUrlPath)}
                </div>
                <div className="text-light">
                  {inTimezone(journey.clickDatetime)}
                </div>
              </div>
            </div>

            {map(journey.conversions, (conversion, index) => (
              <div key={index} className="journey journey-3">
                <div className="journey-icon">
                  <SVG src="/images/overview/icon-journey-2.svg" />
                </div>
                <div className="journey-info">
                  {conversion.conversionName && (
                    <div className="text-dark text-bolder m-b-1">
                      {detailName(conversion.conversionName)}
                    </div>
                  )}
                  <div className="m-b-1">Revenue earned</div>
                  <div className="text-dark text-bolder m-b-1">
                    {formatCurrency(
                      conversion.conversionCommission,
                      conversion.conversionCurrencyCode || 'DKK'
                    )}
                  </div>
                  <div className="text-light">
                    {inTimezone(conversion.conversionDatetime)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </SmallLoadable>
    </div>
  )
}
