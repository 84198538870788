import React from 'react'
import ReactSelect from 'react-select'
import AsyncReactSelect from 'react-select/async'

const styles = {
  control: (provided) => ({
    ...provided,
    minHeight: 36,
    height: 36,
    borderColor: 'var(--btn-border)',
    borderRadius: 6,
    cursor: 'pointer',
    transition: 'border-color 0.3s',
    boxShadow: 'var(--btn-shadow)',

    '&:hover': {
      borderColor: 'var(--btn-border-darken)',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--text-light)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--text-primary)',
    fontWeight: 500,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--text-primary)',
    fontWeight: 500,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),

  menuList: (provided) => ({
    ...provided,
    padding: 6,
    paddingBottom: 0,
    maxHeight: 130,
    borderRadius: 6,
  }),
  option: (provided, state) => ({
    ...provided,
    marginBottom: 6,
    borderRadius: 5,
    color: state.isSelected ? 'var(--text-dark)' : 'var(--text-primary)',
    background: state.isSelected ? 'var(--card-border)' : 'transparent',
    cursor: 'pointer',

    '&:hover': {
      background: 'var(--card-border)',
    },
  }),
}

export const Select = (props) => {
  const { styles: customStyles, ...rest } = props
  return (
    <ReactSelect
      styles={{
        ...styles,
        ...(customStyles || {}),
      }}
      {...rest}
    />
  )
}

export const AsyncSelect = (props) => {
  const { styles: customStyles, ...rest } = props
  return (
    <AsyncReactSelect
      styles={{
        ...styles,
        ...(customStyles || {}),
      }}
      {...rest}
    />
  )
}
