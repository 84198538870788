const scriptmanagerHost = process.env.SCRIPTMANAGER_HOST!.replace(/\/$/, '')
const scriptmanagerPathPrefix = '/scriptmanager/v1'

const scriptmanagerFetch = (path: string, options?: any) => {
  options = options || {}
  options.method = options.method || 'GET'
  options.headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  // options.mode = 'no-cors'

  return window
    .fetch(`${scriptmanagerHost}${scriptmanagerPathPrefix}${path}`, options)
    .then(async (raw) => {
      const data = await raw.json()
      return data
    })
    .catch((error) => {
      console.error(`${path}:`, error)
      return {}
    })
}

export const scriptmanagerGet = (path: string, params?: any) => {
  if (params) {
    const divider = path.includes('?') ? '&' : '?'
    const fullPath = `${path}${divider}${new URLSearchParams(
      params
    ).toString()}`
    return scriptmanagerFetch(fullPath)
  } else {
    return scriptmanagerFetch(path)
  }
}

export const scriptmanagerPost = (path: string, body: any) =>
  scriptmanagerFetch(path, {
    method: 'POST',
    body: JSON.stringify(body),
  })

export const scriptmanagerPut = (path: string, body: any) =>
  scriptmanagerFetch(path, {
    method: 'PUT',
    body: JSON.stringify(body),
  })

export const scriptmanagerDelete = (path: string, body: any) =>
  scriptmanagerFetch(path, {
    method: 'DELETE',
    body: JSON.stringify(body),
  })
