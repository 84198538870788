import React, { useState, useRef } from 'react'
import { useOutsideClick } from '../hooks/outsideClick'
import { varClass } from '../helpers/html'
import { map } from '../helpers/fns'

export const TableSort = (props) => {
  const { sort, setSort, toggleSort, sortOptions, dirOptions } = props

  const [open, setOpen] = useState(false)

  const ref = useRef<any>()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  return (
    <div
      className={varClass({
        'table-sort-dropdown-container m-r-2': true,
        'open': open,
      })}
      ref={ref}
    >
      <button
        className="table-sort-dropdown-btn hover-sort link p-x-2 p-y-1 m-r--2 m-b--1"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <span className="text-light text-bold">Sort by : </span>
        <span className="text-blue text-bold">{sortOptions[sort]}</span>
      </button>

      <div className="table-sort-dropdown item-dropdown">
        {map(sortOptions, (value, label) => (
          <div
            key={value}
            className={varClass({
              'item': true,
              'active': value === sort,
            })}
            onClick={() => {
              if (setSort) setSort(value)
              if (toggleSort)
                toggleSort(
                  value,
                  dirOptions ? dirOptions[value] || 'asc' : 'asc'
                )()
              setOpen(false)
            }}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  )
}
