import React from 'react'
import { countries } from '../helpers/constants'

const countryCodes = Object.keys(countries)

export const CountrySelectOptions = (props) => {
  return (
    <>
      {countryCodes.map((countryCode) => (
        <option key={countryCode} value={countryCode}>
          {countries[countryCode]}
        </option>
      ))}
    </>
  )
}

export const getCountryName = (countryCode: string) => countries[countryCode]
