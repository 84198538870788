import React from 'react'
import { WithTooltip } from './Tooltip'
import { varClass, map } from '../helpers'

export const ValueIndicator = (props) => {
  const { score, tooltipId } = props

  const value = Math.floor(score / 20)
  const label = value < 2 ? 'Low' : value < 4 ? 'Medium' : 'High'

  return (
    <WithTooltip
      className="value-indicator"
      title={label}
      text={
        <>
          {map(5, (_, index) => (
            <div
              key={index}
              className={varClass({
                'pin': true,
                'active': value > index,
              })}
            />
          ))}
        </>
      }
      tooltipId={tooltipId}
      forceShow
    />
  )
}
