import React from 'react'
import SVG from 'react-inlinesvg'

export const LoginIllustration = (props) => {
  return (
    <div className="login-illustration">
      <SVG
        src="/images/signup-illustration-bg.svg"
        className="illustration-bg"
      />
      <div className="signup-illustration-content">
        <img
          src="/images/signup-illustration-fg.png"
          srcSet="/images/signup-illustration-fg.png 1x, /images/signup-illustration-fg.2x.png 2x"
          className="illustration-fg"
          style={{
            maxWidth: 533,
            maxHeight: 763,
          }}
        />
      </div>
    </div>
  )

  // return (
  //   <div className="login-illustration">
  //     <SVG
  //       src="/images/login-illustration-bg.svg"
  //       className="illustration-bg"
  //     />
  //     <div className="login-illustration-content">
  //       <SVG
  //         src="/images/login-illustration-fg.svg"
  //         className="illustration-fg"
  //       />
  //       <div className="login-illustration-text">
  //         <div className="title">
  //           #1 Most Intelligent Partner Management Platform
  //         </div>
  //         <div className="subtitle">
  //           Cloud-based performance tracking platform that will help you
  //           monetize and get insights on all your outbound traffic - entirely
  //           automized.
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export const SignupIllustration = (props) => {
  return (
    <div className="signup-illustration">
      <SVG
        src="/images/signup-illustration-bg.svg"
        className="illustration-bg"
      />
      <div className="signup-illustration-content">
        <img
          src="/images/signup-illustration-fg.png"
          srcSet="/images/signup-illustration-fg.png 1x, /images/signup-illustration-fg.2x.png 2x"
          className="illustration-fg"
          style={{
            maxWidth: 533,
            maxHeight: 763,
          }}
        />
      </div>
    </div>
  )
}
