import { useState, useContext } from 'react'
import { snackbarContext } from '../contexts'
import { TTimeout } from '../types'

let snackbarTimeout: TTimeout | undefined = undefined

export type TSnackbar = {
  snackbarOpen: boolean
  snackbarType: string
  snackbarContent: any
  snackbarPosition: { top: number; left: number } | null
  showSnackbar: (
    content: string,
    options?: { type?: string; time?: number; element?: any }
  ) => void
  hideSnackbar: () => void
}

export const useSnackbar = (): TSnackbar =>
  useContext(snackbarContext) as TSnackbar

export const useSnackbarContext = () => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [snackbarType, setSnackbarType] = useState<string>('')
  const [snackbarContent, setSnackbarContent] = useState<any>(undefined)
  const [snackbarPosition, setSnackbarPosition] = useState<{
    top: number
    left: number
  } | null>(null)

  const showSnackbar = (
    newContent = '',
    options: {
      type?: string
      time?: number
      element?: any
    } = {}
  ) => {
    const type = options?.type || 'info'
    const time = options?.time || 8000

    if (options?.element) {
      const rect = options.element.getBoundingClientRect()
      const scroll = window.scrollY
      const pad = 8
      setSnackbarPosition({
        top: rect.bottom + scroll + pad,
        left: rect.right + pad,
      })
    } else {
      setSnackbarPosition(null)
    }

    setSnackbarOpen(!snackbarOpen)
    if (newContent) {
      setSnackbarType(type)
      setSnackbarContent(newContent)

      if (snackbarTimeout) {
        clearTimeout(snackbarTimeout)
        snackbarTimeout = undefined
      }

      snackbarTimeout = setTimeout(() => {
        setSnackbarOpen(false)
        snackbarTimeout = undefined
      }, time)
    }
  }

  const hideSnackbar = () => showSnackbar(undefined)

  return {
    snackbarOpen,
    snackbarType,
    snackbarContent,
    snackbarPosition,
    showSnackbar,
    hideSnackbar,
  }
}
