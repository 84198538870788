import React from 'react'
import SVG from 'react-inlinesvg'
import { formatStat } from '../helpers'

export const Trend = (props) => {
  const { className, valueFlat, valueCurrency, valuePercentage, hideArrow } =
    props

  let value = 0
  let valueType = valueCurrency || 'percent2'

  if (valueFlat) {
    value = valueFlat
    valueType = valueCurrency || ''
  } else if (valuePercentage) {
    value = valuePercentage
    valueType = 'percent2'
  }

  if (value >= 0) {
    return (
      <span className={`text-bold text-green text-nowrap ${className || ''}`}>
        {formatStat(value, valueType)}
        {!hideArrow && (
          <SVG src="/images/caret-up.svg" className="m-l-1" height={10} />
        )}
      </span>
    )
  } else {
    return (
      <span className={`text-bold text-red text-nowrap ${className || ''}`}>
        {formatStat(value, valueType)}
        {!hideArrow && (
          <SVG src="/images/caret-down.svg" className="m-l-1" height={10} />
        )}
      </span>
    )
  }
}
