import { getCurrentAccountUuid } from '../../../services/auth'

const cachePaths = ['/advs/v1/minlist', '/channels/v1/list']

const memo: Record<string, any> = {}

const formatKey = (path: string, params: any): any => {
  if (typeof params === 'object') {
    return `${getCurrentAccountUuid()}:${path}${JSON.stringify(
      params,
      Object.keys(params).sort()
    )}`
  } else {
    return `${getCurrentAccountUuid()}:${path}{}`
  }
}

export const memoGet = (path: string, params: any): any => {
  if (!cachePaths.includes(path)) return undefined
  return memo[formatKey(path, params)]
}

export const memoSet = (path: string, params: any, value: any): void => {
  if (cachePaths.includes(path)) {
    memo[formatKey(path, params)] = value
  }
}
