import React, { useEffect, createContext, useContext } from 'react'
import { createPortal } from 'react-dom'
import SVG from 'react-inlinesvg'
import { useDrawerContext } from '../hooks'
import { varClass } from '../helpers'

export const drawerContext = createContext({})

export const DrawerContextProvider = (props) => {
  const value = useDrawerContext()

  return (
    <drawerContext.Provider value={value}>
      <Drawer />
      {props.children}
    </drawerContext.Provider>
  )
}

const Drawer = () => {
  const context = useContext<any>(drawerContext)

  const open = context.drawerOpen

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [open])

  return createPortal(
    <div
      className={varClass({
        'drawer': true,
        'open': open,
      })}
      onClick={(event: any) => {
        if (
          event.target.classList.contains('drawer') &&
          event.target.classList.contains('open')
        ) {
          context.hideDrawer()
        }
      }}
    >
      <div
        className={varClass({
          'card': true,
          [context.drawerOptions?.cardClassName || '']:
            !!context.drawerOptions?.cardClassName,
        })}
      >
        <button
          className="drawer-close"
          onClick={() => {
            context.hideDrawer()
          }}
        >
          <SVG src="/images/times.svg" />
        </button>
        {context.drawerContent}
      </div>
    </div>,
    document.querySelector('body')
  )
}
