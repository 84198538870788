import React, { useState, useEffect } from 'react'
import SVG from 'react-inlinesvg'
import { parseQuery } from '../helpers/fns'
import { varClass } from '../helpers/html'

export const useSearch = (): [string, (v: string) => void] => {
  const [value, setValue] = useState('')

  useEffect(() => {
    const query = parseQuery(location.search)
    if (query) {
      if (query.search || query.q) {
        setValue(query.search || query.q)
      }
    }
  }, [])

  return [value, setValue]
}

export const TableSearch = (props) => {
  const { value, setValue, highlight, onSubmit, onCancel, onBlur } = props

  const className = props.className || 'm-l-3'
  const buttonClassName = props.buttonClassName || 'text-primary'
  const inputClassName = props.inputClassName || ''
  const placeholder = props.placeholder || 'Search'

  return (
    <div
      className={varClass({
        'control': true,
        'highlight': highlight,
        [className]: !!className,
      })}
    >
      <button
        className={varClass({
          'link p-l-10 p-r-2': true,
          [buttonClassName]: !!buttonClassName,
        })}
      >
        <SVG
          src="/images/icon-search.svg"
          className="m-t-05"
          width={16}
          height={16}
        />
      </button>
      <input
        type="text"
        placeholder={placeholder}
        className={varClass({
          'text-small p-l-9': true,
          [inputClassName]: !!inputClassName,
        })}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onKeyDown={(event) => {
          if (onSubmit && event.key === 'Enter') {
            onSubmit()
          }
          if (onCancel && event.key === 'Escape') {
            onCancel()
          }
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur()
          }
        }}
      />
    </div>
  )
}

export const SearchSubmit = (props) => {
  const { value, setValue, highlight } = props

  const className = props.className || 'm-l-3'
  const buttonClassName = props.buttonClassName || 'text-primary'
  const inputClassName = props.inputClassName || ''
  const clearClassName = props.clearClassName || 'link link-new-lighter'
  const submitClassName =
    props.submitClassName || 'btn btn-white text-small text-bold m-l--6'
  const submitActiveClassName =
    props.submitActiveClassName || 'border-new-light-blue'
  const placeholder = props.placeholder || 'Search'

  const [shownSearch, setShownSearch] = useState(value)

  return (
    <div className="flex-1 vertical-middle">
      <Search
        value={shownSearch}
        setValue={setShownSearch}
        onSubmit={() => {
          setValue(shownSearch)
        }}
        onBlur={() => {
          setValue(shownSearch)
        }}
        placeholder={placeholder}
        className={className}
        inputClassName={inputClassName}
        buttonClassName={buttonClassName}
        highlight={highlight && !!shownSearch}
      />
      <button
        className={varClass({
          [clearClassName]: true,
          'o-0 unclickable': !shownSearch,
          'o-100': shownSearch,
        })}
        style={{
          position: 'relative',
          top: 2,
          left: -32,
          paddingTop: 4,
          marginTop: -4,
        }}
        onClick={() => {
          setShownSearch('')
          setValue('')
        }}
      >
        <SVG src="/images/icon-times.svg" />
      </button>
      <button
        className={varClass({
          [submitClassName]: true,
          [submitActiveClassName]: !!value,
        })}
        onClick={() => {
          setValue(shownSearch)
        }}
      >
        Search
      </button>
    </div>
  )
}

export const Search = TableSearch
