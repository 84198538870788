import React, { useState, useMemo } from 'react'
import {
  CartesianGrid,
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { cloneDeep } from 'lodash'
import { toUnicode } from 'punycode'
import { varClass, formatStat, isBlank, formatShortStat } from '../helpers'

const colors = {
  // clicks: '#0C74DC',
  // conversions: '#8552EA',
  // commission: '#F0F2F5',
  clicks: '#3FAC73',
  conversions: '#9765FB',
  commission: '#F6DE97',
}

const verticalPoints = []
for (let i = 0; i <= 100; i++) verticalPoints.push(58 * i)

const sortKeys = {
  clicks: 'clickCount',
  conversions: 'conversionCount',
  commission: 'conversionTotalCommission',
}
const sortRowsBy = (key: string) => (a, b) => {
  if (a[sortKeys[key]] < b[sortKeys[key]]) return 1
  if (a[sortKeys[key]] > b[sortKeys[key]]) return -1
  return 0
}
const combineTail = (windowWidth: number) => {
  const tailIndex = windowWidth >= 1200 ? 8 : 5

  return (result, row, index) => {
    if (index <= tailIndex) {
      result.push(row)
    } else {
      result[tailIndex].name = 'Other'
      result[tailIndex].clickCount += row.clickCount
      result[tailIndex].conversionCount += row.conversionCount
      result[tailIndex].conversionTotalCommission +=
        row.conversionTotalCommission
    }
    return result
  }
}

export const PerformanceBarChart = (props) => {
  const { rows } = props

  const [highlight, setHighlight] = useState('clicks')

  const data = useMemo(() => {
    if (!rows || !Array.isArray(rows) || isBlank(rows)) {
      return []
    }

    return cloneDeep(rows)
      .sort(sortRowsBy(highlight))
      .reduce(combineTail(window.innerWidth), [])
      .map((row) => ({
        name: toUnicode(row.name || row.grp || 'Unknown'),
        value: [
          row.clickCount,
          row.conversionCount,
          row.conversionTotalCommission,
        ],
        clicks: row.clickCount,
        conversions: row.conversionCount,
        commission: row.conversionTotalCommission,
      }))
  }, [rows, highlight])

  return (
    <div className="card m-b-20">
      <div className="card-header b-0">
        <div className="row row-space-between">
          <div className="card-title">Performance</div>
          <div>
            <GraphSwitch
              title="Clicks"
              value="clicks"
              currentValue={highlight}
              setValue={setHighlight}
            />
            <GraphSwitch
              title="Conversions"
              value="conversions"
              currentValue={highlight}
              setValue={setHighlight}
            />
            <GraphSwitch
              title="Commission"
              value="commission"
              currentValue={highlight}
              setValue={setHighlight}
            />
          </div>
        </div>
      </div>

      <ResponsiveContainer
        width="99.9%"
        height={350}
        className="performance-bar-chart"
      >
        <BarChart
          data={data}
          margin={{ top: 16, left: 0, right: 0, bottom: 16 }}
          barGap={12}
        >
          <defs>
            <linearGradient id="stroke-clicks" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor={colors.clicks} stopOpacity={1} />
              <stop offset="100%" stopColor={colors.clicks} stopOpacity={1} />
            </linearGradient>
            <linearGradient id="fill-clicks" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={colors.clicks} stopOpacity={1} />
              <stop offset="100%" stopColor={colors.clicks} stopOpacity={1} />
            </linearGradient>

            <linearGradient id="stroke-conversions" x1="0" y1="0" x2="1" y2="0">
              <stop
                offset="0%"
                stopColor={colors.conversions}
                stopOpacity={1}
              />
              <stop
                offset="100%"
                stopColor={colors.conversions}
                stopOpacity={1}
              />
            </linearGradient>
            <linearGradient id="fill-conversions" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="0%"
                stopColor={colors.conversions}
                stopOpacity={1}
              />
              <stop
                offset="100%"
                stopColor={colors.conversions}
                stopOpacity={1}
              />
            </linearGradient>

            <linearGradient id="fill-commission" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor={colors.commission} stopOpacity={1} />
              <stop
                offset="100%"
                stopColor={colors.commission}
                stopOpacity={1}
              />
            </linearGradient>
            <linearGradient id="fill-commission" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={colors.commission} stopOpacity={1} />
              <stop
                offset="100%"
                stopColor={colors.commission}
                stopOpacity={1}
              />
            </linearGradient>
          </defs>

          <CartesianGrid
            verticalPoints={verticalPoints}
            strokeDasharray="6"
            stroke="#EDEFF2"
          />

          <Tooltip cursor={{ fill: '#32528F14' }} content={<GraphTooltip />} />

          {GraphBar('clicks', highlight === 'clicks')}
          {GraphBar('conversions', highlight === 'conversions')}
          {GraphBar('commission', highlight === 'commission')}

          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            // tick={{ fill: '#828B9E' }}
            interval={0}
            tick={<GraphXTick />}
          />
          <YAxis
            yAxisId="y"
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            interval="preserveStartEnd"
            tick={<GraphYTick />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

const GraphSwitch = (props) => {
  const { title, value, currentValue, setValue } = props
  return (
    <button
      className={varClass({
        'btn btn-sm m-l-2': true,
        'btn-active-text': currentValue === value,
      })}
      onClick={() => setValue(value)}
    >
      {title}
    </button>
  )
}

const GraphXTick = (props) => (
  <text x={props.x} y={props.y + 20} textAnchor="middle" fill="#828B9E">
    {props.payload.value}
  </text>
)

const GraphYTick = (props) => (
  <g transform={`translate(${16},${props.y + 2})`}>
    <text x={0} y={0} textAnchor="start" fill="#828B9E">
      {formatShortStat(props.payload.value)}
    </text>
  </g>
)

const GraphBar = (key, active) => (
  <Bar
    type="linear"
    dataKey={key}
    yAxisId="y"
    barSize={12}
    radius={[4, 4, 0, 0]}
    stroke={`url(#stroke-${key})`}
    strokeWidth={1}
    fill={`url(#fill-${key})`}
    fillOpacity={1}
  />
)

const GraphTooltip = (props) => {
  const { active, payload } = props

  if (!active) {
    return null
  }

  const name = payload[0].payload.name || 'Performance'
  const clicks = payload[0].payload.clicks || 0
  const conversions = payload[0].payload.conversions || 0
  const commission = payload[0].payload.commission || 0

  return (
    <div className="chart-tooltip">
      <div className="chart-tooltip-header">{name}</div>
      <div className="chart-tooltip-body">
        <ul>
          {clicks !== undefined && (
            <li className="chart-tooltip-clicks">
              <span>Clicks</span> <span>{formatStat(clicks)}</span>
            </li>
          )}
          {conversions !== undefined && (
            <li className="chart-tooltip-conversions">
              <span>Conversions</span> <span>{formatStat(conversions)}</span>
            </li>
          )}
          {commission !== undefined && (
            <li className="chart-tooltip-commission">
              <span>Commission</span> <span>{formatStat(commission)}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
