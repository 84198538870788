import Cookies from 'js-cookie'
import { invokeModal } from '../components/shared/helpers'

const adblockCookie = 'adb_check'
const checkTimeouts = [1000, 3000, 5000]

export const checkAdblock = (force = false) => {
  if (!force && Cookies.get(adblockCookie)) return
  Cookies.set(adblockCookie, 'true')
  let detected = false
  for (const t of checkTimeouts) {
    setTimeout(async () => {
      const { default: adblockDetector } = await import('adblock-detector')
      if (detected) return
      if (adblockDetector.detect()) {
        detected = true
        invokeModal('adblock-modal')
      }
    }, t)
  }
}
