const generateCode = (): string => {
  const length = 64 + Math.ceil(Math.random() * 64)
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

const generateChallenge = async (codeVerifier: string): Promise<string> => {
  const digest = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(codeVerifier)
  )
  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

export const daisyconOauth = async () => {
  const code = generateCode()
  window.sessionStorage.setItem('daisycon_code', code)

  const challenge = await generateChallenge(code)

  const params = {
    response_type: 'code',
    client_id: process.env.DAISYCON_CLIENT_ID!,
    code_challenge_method: 'S256',
    code_challenge: challenge,
    redirect_uri: window.location.origin + '/networks#daisycon',
  }

  document.location = `https://login.daisycon.com/oauth/authorize?${new URLSearchParams(
    params
  ).toString()}`
}

export const daisyconOauthTokens = async (code: string) => {
  const verifier = window.sessionStorage.getItem('daisycon_code')
  if (!verifier) return
  window.sessionStorage.removeItem('daisycon_code')

  const response = await fetch(
    'https://login.daisycon.com/oauth/access-token',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grant_type: 'authorization_code',
        code: code,
        client_id: process.env.DAISYCON_CLIENT_ID!,
        client_secret: '',
        redirect_uri: window.location.origin + '/networks#daisycon',
        code_verifier: verifier,
      }),
    }
  )

  return response.json()
}
