import React, { createContext, useContext } from 'react'
import { createPortal } from 'react-dom'
import { useSnackbarContext } from '../hooks'
import { varClass } from '../helpers'

export const snackbarContext = createContext({})

export const SnackbarContextProvider = (props) => {
  const value = useSnackbarContext()

  return (
    <snackbarContext.Provider value={value}>
      <Snackbar />
      {props.children}
    </snackbarContext.Provider>
  )
}

const Snackbar = () => {
  const context = useContext<any>(snackbarContext)

  const open = context.snackbarOpen

  return createPortal(
    <div
      className={varClass({
        'snackbar': true,
        [`snackbar-${context.snackbarType || 'info'}`]: true,
        'open': open,
      })}
      onClick={(event: any) => {
        if (
          event.target.classList.contains('snackbar') &&
          event.target.classList.contains('open')
        ) {
          context.hideSnackbar()
        }
      }}
      style={
        context.snackbarPosition
          ? {
              position: 'absolute',
              top: context.snackbarPosition.top,
              left: context.snackbarPosition.left,
              right: 'unset',
              transition: 'opacity 0.3s',
            }
          : {}
      }
    >
      {context.snackbarContent}
    </div>,
    document.querySelector('body')
  )
}
