import React, { useState } from 'react'
import { toUnicode } from 'punycode'
import { varClass } from '..'

export const Logo = (props) => {
  const {
    src,
    text,
    letter,
    width,
    height,
    className: userClassName,
    bordered,
  } = props

  const [errored, setErrored] = useState(false)
  const className = varClass({
    'logo': true,
    'logo-bordered': bordered,
    'logo-letter': !src && !text && letter,
    [userClassName || '']: !!userClassName,
  })

  if (errored) {
    return null
  }

  if (src) {
    return (
      <img
        className={className}
        src={src}
        alt={text || src}
        width={width}
        height={height}
        onError={({ currentTarget }) => {
          if (!errored) {
            setErrored(true)
          }
        }}
      />
    )
  } else if (text) {
    return (
      <div className={className} style={{ width, height }}>
        {text}
      </div>
    )
  } else if (letter) {
    return (
      <div className={className} style={{ width, height }}>
        {toUnicode(letter)[0]}
      </div>
    )
  } else {
    return <div className={className} style={{ width, height }} />
  }
}
