import React from 'react'
import SVG from 'react-inlinesvg'
import { Link, varClass } from '..'

export const Header = (props) => {
  const { backPath, label, backLabel, backLabelClass } = props

  return (
    <>
      {backPath && (
        <Link
          className="link link-dark text-bolder vertical-middle m-b-30"
          href={backPath}
        >
          <SVG
            src="/images/arrow-back2.svg"
            className="m-t--05 m-r-3"
            width={20}
            height={20}
          />
          {backLabel ?
            <span
              className={varClass({
                [backLabelClass]: !!backLabelClass,
              })}>
              {backLabel}
            </span> :
            'Go back'
          }
        </Link>
      )}
      {label && (
        <div className="text-dark text-bigger text-bolder m-t-30 m-b-6">
          {label}
        </div>
      )}
    </>
  )
}
