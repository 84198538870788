import React from 'react'
import { varClass } from '..'

export const Loadable = (props) => {
  const { data, loaded, placeholder } = props
  const Placeholder = placeholder

  if (data || loaded) {
    return props.children || null
  } else if (Placeholder) {
    return <div>{Placeholder}</div>
  } else {
    return <div className="loader" />
  }
}

export const SmallLoadable = (props) => {
  const { data, loaded, height, table, className, style } = props

  const margin = height ? (height - 28) / 2 : 0

  if (data || loaded) {
    return props.children || null
  } else if (table) {
    return (
      <tr>
        <td colSpan={table}>
          <div
            className={varClass({
              'loader-sm': true,
              [className]: !!className,
            })}
            style={{
              marginTop: margin,
              marginBottom: margin,
              ...(style || {}),
            }}
          />
        </td>
      </tr>
    )
  } else {
    return (
      <div
        className={varClass({
          'loader-sm': true,
          [className]: !!className,
        })}
        style={{
          marginTop: margin,
          marginBottom: margin,
          ...(style || {}),
        }}
      />
    )
  }
}

export const Load = (props) => {
  const loadProps = { ...props }
  loadProps.className = `placeholder-shimmer ${props.className || ''}`.trim()
  loadProps.style = loadProps.style || {}
  loadProps.style.width = props.width || '100%'
  loadProps.style.height = props.height || '16px'
  loadProps.style.minWidth = props.minWidth || ''
  loadProps.style.minHeight = props.minHeight || ''
  loadProps.style.maxWidth = props.maxWidth || ''
  loadProps.style.maxHeight = props.maxHeight || ''

  delete loadProps.width
  delete loadProps.height
  delete loadProps.minWidth
  delete loadProps.minHeight
  delete loadProps.maxWidth
  delete loadProps.maxHeight

  return <div {...loadProps}>&nbsp;</div>
}
