import React, { useRef, useState } from 'react'
import { useOutsideClick, varClass } from '..'
import { postRequest } from '../../../services'

const statusLabels = {
  '': 'No status',
  'todo': 'To do',
  'inprogress': 'In progress',
  'notnow': 'Not now',
  'no': 'No',
  'complete': 'Completed',
}

export const AdvertiserStatus = (props) => {
  const { advertiserUuid, advertiserDomain, initialStatus } = props

  const [status, setStatus] = useState(initialStatus)
  const [open, setOpen] = useState(false)
  const containerRef = useRef()

  useOutsideClick(containerRef, () => setOpen(false))

  const onChange = async (newStatus: string) => {
    const oldStatus = status
    setStatus(newStatus)
    setOpen(false)
    try {
      await postRequest(`/advs/v1/status`, {
        advertiserUuid,
        advertiserDomain,
        advertiserStatus: newStatus,
      })
    } catch (e) {
      setStatus(oldStatus)
    }
  }

  if (!advertiserUuid && !advertiserDomain) {
    return null
  }

  return (
    <div
      className={varClass({
        'merchant-status-select': true,
        'open': open,
      })}
      ref={containerRef}
    >
      <span
        className={varClass({
          'badge badge-hoverable text-smaller text-nowrap m-05': true,
        })}
        onClick={() => setOpen(!open)}
      >
        {statusLabels[status]}
      </span>
      <ul>
        <li>
          <button className="link" onClick={() => onChange('')}>
            Clear
          </button>
        </li>
        <li>
          <button className="link" onClick={() => onChange('todo')}>
            To do
          </button>
        </li>
        <li>
          <button className="link" onClick={() => onChange('inprogress')}>
            In progress
          </button>
        </li>
        <li>
          <button className="link" onClick={() => onChange('notnow')}>
            Not now
          </button>
        </li>
        <li>
          <button className="link" onClick={() => onChange('no')}>
            No
          </button>
        </li>
        <li>
          <button className="link" onClick={() => onChange('complete')}>
            Completed
          </button>
        </li>
      </ul>
    </div>
  )
}
