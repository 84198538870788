import React, { useState, useEffect, useMemo } from 'react'
import { PhoneInput, defaultCountries } from 'react-international-phone'
import { varClass } from '../helpers'

export const CountryPhoneInput = (props) => {
  const { name, initialCode, initialPhone, onChange, dropdownOnTop } = props

  const [valuePhone, setValuePhone] = useState('+' + initialCode + initialPhone)
  const defaultCountry = useMemo(() => {
    const countryData = defaultCountries.find(
      (countryData) => countryData[3] === initialCode
    )
    return countryData ? countryData[2] : 'dk'
  }, [initialCode])
  const [valueCountry, setValueCountry] = useState(defaultCountry)

  useEffect(() => {
    setValuePhone(initialPhone)
  }, [])

  return (
    <PhoneInput
      className={varClass({
        'dropdown-on-top': dropdownOnTop,
      })}
      // name={name}
      value={valuePhone}
      defaultCountry={defaultCountry}
      onChange={(phone, country) => {
        const countryData = defaultCountries.find(
          (countryData) => countryData[2] === country
        )
        const code = countryData ? countryData[3] : '45'
        onChange({
          phone: phone.replace(/\D/g, ''),
          code,
        })
        setValuePhone(phone)
        setValueCountry(country)
      }}
      disableDialCodeAndPrefix
      countrySelectorStyleProps={{
        style: {
          // TODO: fix dropdown width
        },
      }}
    />
  )
}

export const CountryPhoneWithDialInput = (props) => {
  const { name, initialCode, initialPhone, onChange, dropdownOnTop } = props

  const [valuePhone, setValuePhone] = useState('+' + initialCode + initialPhone)
  const defaultCountry = useMemo(() => {
    const countryData = defaultCountries.find(
      (countryData) => countryData[3] === initialCode
    )
    return countryData ? countryData[2] : 'dk'
  }, [initialCode])
  const [valueCountry, setValueCountry] = useState(defaultCountry)

  return (
    <PhoneInput
      className={varClass({
        'dropdown-on-top': dropdownOnTop,
      })}
      // name={name}
      value={valuePhone}
      defaultCountry={defaultCountry}
      onChange={(phone, country) => {
        const countryData = defaultCountries.find(
          (countryData) => countryData[2] === country
        )
        const code = countryData ? countryData[3] : '45'
        onChange({
          phone: phone.replace(/\D/g, ''),
          code,
        })
        setValuePhone(phone)
        setValueCountry(country)
      }}
      prefix={''}
      countrySelectorStyleProps={{
        style: {
          // TODO: fix dropdown width
        },
      }}
    />
  )
}
