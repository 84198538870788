import React, { useState, useEffect, useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import { useInterval } from '../hooks'

export const Tooltip = (props) => {
  const { rebuild, ...tooltipProps } = props

  useEffect(() => {
    ReactTooltip.rebuild()
  }, rebuild || [])

  useEffect(() => {
    setTimeout(ReactTooltip.rebuild, 300)
  }, [])

  return <ReactTooltip effect="solid" {...tooltipProps} />
}

export const DynamicTooltip = (props) => {
  const interval = useInterval(3000)
  return <ReactTooltip key={interval} type="solid" {...props} />
}

export const WithTooltip = (props) => {
  const {
    className,
    style,
    label,
    title,
    text,
    forceShow,
    placement,
    tooltipId,
    rebuild,
  } = props

  const ref = useRef<any>(null)
  const [isOverflown, setIsOverflown] = useState(false)
  useEffect(() => {
    if (ref.current) {
      const element = ref.current!
      setIsOverflown(element.scrollWidth > element.clientWidth)
    }
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, rebuild || [])

  const showTitle =
    forceShow ||
    isOverflown ||
    (ref.current ? ref.current.scrollWidth > ref.current.clientWidth : false)
  const renderTooltip = !tooltipId && showTitle

  return (
    <>
      <div
        className={className}
        data-tip={showTitle ? title || label : undefined}
        data-for={tooltipId}
        ref={ref}
        style={style}
      >
        {text || label}
      </div>
      {renderTooltip && (
        <ReactTooltip
          type="dark"
          effect="solid"
          place={placement || 'bottom'}
        />
      )}
    </>
  )
}
