import { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import useIsMounted from 'ismounted'
import { getToken, checkToken } from '../../../services/auth'

export const useAuth = (params: {
  redirectAuthenticated?: string
  redirectUnauthenticated?: string
}) => {
  const isMounted = useIsMounted()

  const [authenticated, setAuthenticated] = useState<boolean | null>(null)

  useEffect(() => {
    const callback = async () => {
      const token = getToken()
      const tokenCorrect = await checkToken(token || '')

      if (isMounted.current) {
        if (token && tokenCorrect) {
          setAuthenticated(true)
          if (params.redirectAuthenticated) {
            navigate(params.redirectAuthenticated)
          }
        } else {
          setAuthenticated(false)
          if (params.redirectUnauthenticated) {
            navigate(params.redirectUnauthenticated)
          }
        }
      }
    }
    callback()
  }, [])

  return authenticated
}
