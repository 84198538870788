import { useState } from 'react'

type TSortableTableDefaults = {
  sort?: string
  dir?: 'asc' | 'desc'
}

export const useSortableTable = (defaults: TSortableTableDefaults = {}) => {
  const [sort, setSort] = useState(defaults.sort || '')
  const [dir, setDir] = useState(defaults.dir || 'asc')

  const toggleSort = (newSort: string, defaultDir: 'asc' | 'desc') => () => {
    if (sort === newSort) {
      if (dir === defaultDir) {
        if (dir === 'asc') {
          setDir('desc')
        } else {
          setDir('asc')
        }
      } else {
        if (defaults.sort) {
          if (dir === 'asc') {
            setDir('desc')
          } else {
            setDir('asc')
          }
        } else {
          setSort('')
          setDir('asc')
        }
      }
    } else {
      setSort(newSort)
      setDir(defaultDir)
    }
  }

  return { sort, dir, toggleSort }
}
