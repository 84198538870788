import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import SVG from 'react-inlinesvg'
import { cloneDeep, isEqual } from 'lodash'
import { toUnicode } from 'punycode'
import {
  map,
  isBlank,
  varClass,
  host,
  plural,
  filterBySearch,
  countrySort,
  responseError,
  navigateReload,
  withCurrencyNetworkConstraint,
} from '../helpers'
import {
  useMemoPresent,
  useCounter,
  useModal,
  useFormSubmit,
  useApiGet,
} from '../hooks'
import {
  INetworksV1Network,
  ICountriesV1List,
  ICurrenciesV1List,
  TTimeout,
} from '../types'
import { SmallLoadable, Logo, Search } from '.'
import { postRequest, deleteRequest, daisyconOauth } from '../../../services'

const connectionSort = (a, b) => {
  if (a.connectionChannelName < b.connectionChannelName) {
    return -1
  } else if (a.connectionChannelName > b.connectionChannelName) {
    return 1
  } else {
    return 0
  }
}

const NetworkList = (props) => {
  const { label, networks, onSelect } = props

  const [networkSearch, setNetworkSearch] = useState('')

  const shownNetworks = filterBySearch(
    networks,
    networkSearch,
    (network: { networkName: string }) => [network.networkName]
  )

  return (
    <>
      <div className="card-header">
        <div className="card-title">Connect network</div>
      </div>
      <div className="card-body">
        <div className="m-b-20">{label}</div>
        <Search
          value={networkSearch}
          setValue={setNetworkSearch}
          className="control-focusless w-100 m-0"
          inputClassName="w-100"
          placeholder="Find network"
        />
        <div className="text-small text-bold m-t-20 m-b-10">
          Popular networks
        </div>
        <ul className="connect-network-list">
          {map(shownNetworks, (network) => (
            <li
              key={network.networkUuid}
              onClick={(event) => {
                if (!(event.target as any).closest('a')) {
                  onSelect(network)
                }
              }}
            >
              <div className="row row-center">
                <div className="card card-logo borderless">
                  <Logo
                    src={network.networkLogoUrl}
                    text={network.networkName}
                    width={74}
                    height={20}
                  />
                </div>
                <div>
                  <div className="text-dark text-bolder">
                    {network.networkName}
                  </div>
                  <a
                    href={`https://${network.networkUrl}`}
                    className="text-small link link-light text-small vertical-middle"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>{network.networkUrl}</span>
                    <SVG src="/images/icon-outside.svg" className="m-l-2" />
                  </a>
                </div>
              </div>
              <SVG src="/images/chevron2-right.svg" className="chevron m-r-3" />
            </li>
          ))}
          {isBlank(shownNetworks) && <li className="blank">Nothing found</li>}
        </ul>
      </div>
    </>
  )
}

const NetworkSignin = (props) => {
  const { network, goBack, onDone, submitConnection } = props

  const form = useForm()
  const { register, handleSubmit } = form

  const [error, setError] = useState('')

  const countries = useApiGet<ICountriesV1List[]>('/countries/v1/list')
  const sortedCountries = useMemo(
    () => countries && countries.sort(countrySort),
    [countries]
  )

  const currencies = useApiGet<ICurrenciesV1List[]>('/currencies/v1/list')
  const sortedCurrencies = useMemo(
    () =>
      currencies &&
      currencies
        .map((currency) => currency.currencyCode)
        .filter(withCurrencyNetworkConstraint(network.networkIdentifier)),
    [currencies]
  )

  // Custom network changes
  // Amazon
  const [amazonChoice, setAmazonChoice] = useState('')

  const showSubmit =
    network.networkIdentifier !== 'daisycon' &&
    !(network.networkIdentifier === 'amazon' && !amazonChoice)

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    setError('')

    if (
      Object.prototype.hasOwnProperty.call(values, 'connectionApiCountryCode')
    ) {
      values.connectionApiCountryCode =
        values.connectionApiCountryCode || sortedCountries[0].countryCode
    }
    if (
      Object.prototype.hasOwnProperty.call(values, 'connectionCpcCurrencyCode')
    ) {
      values.connectionCpcCurrencyCode =
        values.connectionCpcCurrencyCode || sortedCurrencies[0]
    }
    if (Object.prototype.hasOwnProperty.call(values, 'connectionCpcValue')) {
      values.connectionCpcValue = parseFloat(values.connectionCpcValue)
    }

    const response = await postRequest('/networks/v1/connections', {
      connectionNetworkIdentifier: network.networkIdentifier,
      ...values,
    })

    if (response) {
      if (response.code === 200) {
        onDone(response.data)
      } else {
        setError(responseError(response))
      }
    }
  })

  useEffect(() => {
    if (submitConnection) {
      onSubmit(submitConnection)
    }
  }, [])

  if (submitConnection && !error) {
    // Faking a channel fetch while submitting credentials
    return (
      <>
        <div className="card-header">
          <div className="card-title">Map affiliate channels</div>
        </div>
        <div className="card-body">
          <div className="m-b-30">
            By mapping the affiliate channels that matches your{' '}
            {network.networkName}, you are able to get in-depth insights on the
            traffic going out from this channel.
          </div>

          <div className="connect-mapping-list loading">
            <div className="list-header">
              <div className="card card-logo borderless">
                <Logo
                  src={network.networkLogoUrl}
                  text={network.networkName}
                  width={74}
                  height={20}
                />
              </div>
              <div className="text-dark text-bolder">{network.networkName}</div>
            </div>
            <div className="row row-center row-space-between p-y-3 p-x-4">
              <div className="text-light text-smaller">
                Fetching channels...
              </div>
              <div className="loading-bar" />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="card-header">
        {goBack ? (
          <button
            className="link link-dark text-big text-bolder vertical-middle"
            onClick={() => {
              if (!submitting) {
                goBack()
              }
            }}
          >
            <SVG
              src="/images/chevron2-right.svg"
              className="rotate-180 m-t--05 m-r-20"
            />
            Back
          </button>
        ) : (
          <div className="card-title">Connect network</div>
        )}
      </div>
      <div className="card-body">
        <div className="text-right">
          <Logo
            src={network.networkLogoUrl}
            text={network.networkName}
            width={100}
            height={24}
            className="logo-right"
          />
        </div>
        <div className="text-dark text-bigger text-bolder m-t-3">
          {network.networkName}
        </div>
        <div className="m-t-4 m-b-30">
          Sign in to your {network.networkName} account to start importing
          traffic data connected to this network.
        </div>
        <SmallLoadable loaded={countries && currencies}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {network.networkIdentifier === 'amazon' ? (
              <>
                {amazonChoice === '' ? (
                  <div className="column column-center w-60 m-x-auto m-b-5">
                    <button
                      className="btn btn-dark w-100"
                      onClick={() => {
                        setAmazonChoice('auto')
                      }}
                    >
                      Activity Report Feed login
                    </button>
                    <button
                      className="btn btn-dark w-100"
                      onClick={() => {
                        setAmazonChoice('manual')
                      }}
                    >
                      Manual conversions CSV upload
                    </button>
                  </div>
                ) : (
                  <>
                    {amazonChoice === 'auto' && (
                      <>
                        {(network.networkConnectionApiUsernameIsRequired ||
                          network.networkConnectionApiPasswordIsRequired) && (
                          <div className="row row-nowrap">
                            {network.networkConnectionApiUsernameIsRequired && (
                              <div className="control flex-1 flex-1-2 m-b-5">
                                <label>
                                  {network.networkConnectionApiUsernameText ||
                                    'User name'}
                                </label>
                                <input
                                  type="text"
                                  {...register('connectionApiUsername', {
                                    required: true,
                                  })}
                                />
                              </div>
                            )}

                            {network.networkConnectionApiPasswordIsRequired && (
                              <div className="control flex-1 flex-1-2 m-b-5">
                                <label>
                                  {network.networkConnectionApiPasswordText ||
                                    'Password'}
                                </label>
                                <input
                                  type="password"
                                  {...register('connectionApiPassword', {
                                    required: true,
                                  })}
                                />
                              </div>
                            )}
                          </div>
                        )}

                        {network.networkConnectionApiKeyIsRequired && (
                          <div className="control control-select block m-b-5">
                            <label>
                              {network.networkConnectionApiKeyText || 'Locale'}
                            </label>
                            <select {...register('connectionApiKey')}>
                              <option value="na">
                                assoc-datafeeds-na.amazon.com
                              </option>
                              <option value="eu">
                                assoc-datafeeds-eu.amazon.com
                              </option>
                              <option value="fe" selected>
                                assoc-datafeeds-fe.amazon.com
                              </option>
                            </select>
                          </div>
                        )}
                      </>
                    )}

                    {network.networkConnectionApiPartnerIdIsRequired && (
                      <div className="control block m-b-5">
                        <label>
                          {network.networkConnectionApiPartnerIdText ||
                            'Partner ID'}
                        </label>
                        <input
                          type="text"
                          {...register('connectionApiPartnerId', {
                            required: true,
                          })}
                        />
                      </div>
                    )}

                    {network.networkConnectionApiCountryCodeIsRequired && (
                      <div className="row">
                        <div className="control control-select block flex-1-2 m-b-5">
                          <label>
                            {network.networkConnectionApiCountryCodeText ||
                              'Country code'}
                          </label>
                          <select {...register('connectionApiCountryCode')}>
                            <option value="US" selected>
                              US: amazon.com
                            </option>
                            <option value="GB">UK: amazon.co.uk</option>
                            <option value="AU">AU: amazon.com.au</option>
                            <option value="CA">CAN: amazon.ca</option>
                            <option value="DE">DE: amazon.de</option>
                            <option value="SE">SE: amazon.se</option>
                            <option value="FR">FR: amazon.fr</option>
                            <option value="IN">IN: amazon.in</option>
                            <option value="IT">IT: amazon.it</option>
                            <option value="AE">AE: amazon.ae</option>
                            <option value="ES">ES: amazon.es</option>
                            <option value="PL">PL: amazon.pl</option>
                            <option value="BE">BE: amazon.com.be</option>
                            <option value="NL">NL: amazon.nl</option>
                          </select>
                        </div>
                      </div>
                    )}

                    {(network.networkConnectionCpcValueIsRequired ||
                      network.networkConnectionCpcCurrencyCodeIsRequired) && (
                      <div className="row">
                        {network.networkConnectionCpcCurrencyCodeIsRequired && (
                          <div className="control control-select block flex-1-2 m-b-5">
                            <label>
                              {network.networkConnectionCpcCurrencyCodeText ||
                                'CPC currency'}
                            </label>
                            <select {...register('connectionCpcCurrencyCode')}>
                              {map(sortedCurrencies, (currency) => (
                                <option key={currency} value={currency}>
                                  {currency}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        {network.networkConnectionCpcValueIsRequired && (
                          <div className="control flex-1 m-b-5">
                            <label>
                              {network.networkConnectionCpcValueText ||
                                'CPC value'}
                            </label>
                            <input
                              type="text"
                              {...register('connectionCpcValue', {
                                pattern: /^[0-9.]+$/,
                              })}
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {network.networkIdentifier === 'daisycon' && (
                      <div className="row row-center row-space-between m-t-8">
                        <div />
                        <button
                          type="button"
                          id="network-modal-signin-btn"
                          className="btn btn-dark m-l-auto text-nowrap"
                          onClick={daisyconOauth}
                          disabled={submitting}
                        >
                          Connect {network.networkName}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {(network.networkConnectionApiUsernameIsRequired ||
                  network.networkConnectionApiPasswordIsRequired) && (
                  <div className="row row-nowrap">
                    {network.networkConnectionApiUsernameIsRequired && (
                      <div className="control flex-1 flex-1-2 m-b-5">
                        <label>
                          {network.networkConnectionApiUsernameText ||
                            'User name'}
                        </label>
                        <input
                          type="text"
                          {...register('connectionApiUsername', {
                            required: true,
                          })}
                        />
                      </div>
                    )}

                    {network.networkConnectionApiPasswordIsRequired && (
                      <div className="control flex-1 flex-1-2 m-b-5">
                        <label>
                          {network.networkConnectionApiPasswordText ||
                            'Password'}
                        </label>
                        <input
                          type="password"
                          {...register('connectionApiPassword', {
                            required: true,
                          })}
                        />
                      </div>
                    )}
                  </div>
                )}

                {network.networkConnectionApiKeyIsRequired && (
                  <div className="control block m-b-5">
                    <label>
                      {network.networkConnectionApiKeyText || 'Api key'}
                    </label>
                    <input
                      type="text"
                      {...register('connectionApiKey', { required: true })}
                    />
                  </div>
                )}

                {network.networkConnectionApiPartnerIdIsRequired && (
                  <div className="control block m-b-5">
                    <label>
                      {network.networkConnectionApiPartnerIdText ||
                        'Partner ID'}
                    </label>
                    <input
                      type="text"
                      {...register('connectionApiPartnerId', {
                        required: true,
                      })}
                    />
                  </div>
                )}

                {network.networkConnectionApiCountryCodeIsRequired && (
                  <div className="control control-select block m-b-5">
                    <label>
                      {network.networkConnectionApiCountryCodeText ||
                        'Country code'}
                    </label>
                    <select {...register('connectionApiCountryCode')}>
                      {map(sortedCountries, (country) => (
                        <option
                          key={country.countryCode}
                          value={country.countryCode}
                        >
                          {country.countryName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {(network.networkConnectionCpcValueIsRequired ||
                  network.networkConnectionCpcCurrencyCodeIsRequired) && (
                  <div className="row">
                    {network.networkConnectionCpcCurrencyCodeIsRequired && (
                      <div className="control control-select block flex-1-3 m-b-5">
                        <label>
                          {network.networkConnectionCpcCurrencyCodeText ||
                            'CPC currency'}
                        </label>
                        <select {...register('connectionCpcCurrencyCode')}>
                          {map(sortedCurrencies, (currency) => (
                            <option key={currency} value={currency}>
                              {currency}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {network.networkConnectionCpcValueIsRequired && (
                      <div className="control flex-1 m-b-5">
                        <label>
                          {network.networkConnectionCpcValueText || 'CPC value'}
                        </label>
                        <input
                          type="text"
                          {...register('connectionCpcValue', {
                            pattern: /^[0-9.]+$/,
                          })}
                        />
                      </div>
                    )}
                  </div>
                )}

                {network.networkIdentifier === 'daisycon' && (
                  <div className="row row-center row-space-between m-t-8">
                    <div />
                    <button
                      type="button"
                      id="network-modal-signin-btn"
                      className="btn btn-dark m-l-auto text-nowrap"
                      onClick={daisyconOauth}
                      disabled={submitting}
                    >
                      Connect {network.networkName}
                    </button>
                  </div>
                )}
              </>
            )}

            {showSubmit && (
              <div className="row row-center row-space-between m-t-8">
                {!submitting && !error && <div />}
                {submitting && (
                  <div className="loader-sm" style={{ margin: 0 }} />
                )}
                {error && <div className="text-red m-r-4">{error}</div>}
                <button
                  type="submit"
                  id="network-modal-signin-btn"
                  className="btn btn-dark m-l-auto text-nowrap"
                  disabled={submitting}
                >
                  Connect {network.networkName}
                </button>
              </div>
            )}
          </form>
        </SmallLoadable>
      </div>
    </>
  )
}

const useChannelSelection = (initialSelectedChannels = {}) => {
  const [selectedChannels, setSelectedChannels] = useState(
    initialSelectedChannels
  )
  const toggleSelectedChannel = (channel, connectionChannel: any) => {
    const newSelectedChannels = cloneDeep(selectedChannels)
    newSelectedChannels[channel.channelUuid] =
      newSelectedChannels[channel.channelUuid] || []
    const connectionChannelUuidIndex = newSelectedChannels[
      channel.channelUuid
    ].indexOf(connectionChannel.connectionChannelUuid)
    if (connectionChannelUuidIndex === -1) {
      newSelectedChannels[channel.channelUuid].push(
        connectionChannel.connectionChannelUuid
      )
    } else {
      newSelectedChannels[channel.channelUuid].splice(
        connectionChannelUuidIndex,
        1
      )
    }
    setSelectedChannels(newSelectedChannels)
  }
  const isChannelSelected = (channel, connectionChannel: any) =>
    selectedChannels[channel.channelUuid] &&
    selectedChannels[channel.channelUuid].includes(
      connectionChannel.connectionChannelUuid
    )
  const selectedChannelsCount: number = Object.values(
    selectedChannels
  ).reduce<number>(
    (result: number, connectionChannels: any) =>
      result + connectionChannels.length,
    0
  )

  return {
    selectedChannels,
    setSelectedChannels,
    toggleSelectedChannel,
    isChannelSelected,
    selectedChannelsCount,
  }
}

const ChannelMappingList = (props) => {
  const { channels, network, connectionUuid, onSkip, onDone } = props

  const {
    selectedChannels,
    toggleSelectedChannel,
    isChannelSelected,
    selectedChannelsCount,
  } = useChannelSelection()

  const [connectionsLoaded, setConnectionsLoaded] = useState(-1)
  const [reloadingNetworkDetails, setReloadingNetworkDetails] = useState(true)
  const [reloadNetworkDetailsRef, reloadNetworkDetails] = useCounter()
  const networkDetails = useApiGet<INetworksV1Network>(
    `/networks/v1/networks/${network?.networkUuid}`,
    {},
    [reloadNetworkDetailsRef]
  )

  const loadingMoreConnections =
    useMemo(() => {
      if (reloadingNetworkDetails && networkDetails === null) {
        setReloadingNetworkDetails(false)
        return false
      } else {
        return reloadingNetworkDetails
      }
    }, [networkDetails]) || reloadingNetworkDetails

  const noConnections = connectionsLoaded === 0 && !loadingMoreConnections

  const connection = useMemoPresent(
    networkDetails && connectionUuid
      ? networkDetails.connections?.find(
          (connection) => connection.connectionUuid === connectionUuid
        )
      : null
  )

  const [submitMappings, submittingMappings] = useFormSubmit(async (values) => {
    for (const channelUuid of Object.keys(selectedChannels)) {
      const connectionChannelUuids = selectedChannels[channelUuid]
      for (const connectionChannelUuid of connectionChannelUuids) {
        try {
          await postRequest(
            `/channels/v1/channel/${channelUuid}/connectionchannels`,
            {
              connectionChannelUuid,
            }
          )
        } catch (error) {
          console.log(error)
        }
      }
    }
    onDone()
  })

  const connectionsReload = useRef<{ timeout: TTimeout }>({
    timeout: null,
  }).current
  if (connectionsLoaded < 0 && !connection && !connectionsReload.timeout) {
    connectionsReload.timeout = setTimeout(() => {
      delete connectionsReload.timeout
      reloadNetworkDetails()
    }, 10000)
  } else if (
    connection &&
    connection.connectionChannels.length > connectionsLoaded
  ) {
    if (!connectionsReload.timeout) {
      connectionsReload.timeout = setTimeout(() => {
        delete connectionsReload.timeout
        reloadNetworkDetails()
      }, 5000)
      setReloadingNetworkDetails(true)
    }
    if (connection.connectionChannels.length) {
      setConnectionsLoaded(connection.connectionChannels.length)
    }
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Map affiliate channels</div>
      </div>
      <div className="card-body">
        <div className="m-b-30">
          By mapping the affiliate channels that matches your{' '}
          {network.networkName}, you are able to get in-depth insights on the
          traffic going out from this channel.
        </div>

        {connectionsLoaded > 0 && (
          <div className="connect-mapping-list">
            {loadingMoreConnections && (
              <div className="row row-center row-space-between p-y-3 p-x-4">
                <div className="text-light text-smaller">
                  Fetching more channels...
                </div>
                <div className="loading-bar" />
              </div>
            )}

            {map(channels, (channel) => (
              <React.Fragment key={channel.channelUuid}>
                <div className="list-header">
                  <Logo
                    src={
                      channels.find(
                        (channelDetails) =>
                          channelDetails.channelUuid === channel.channelUuid
                      )?.channelFaviconUrl
                    }
                    letter={channel.channelDomain || channel.channelName || ''}
                    width={28}
                    height={28}
                    bordered={true}
                  />
                  <span className="text-dark text-big text-bolder m-l-10">
                    {channel.channelDomain}
                  </span>
                </div>
                <div className="text-light text-small text-bold m-4 m-b-10">
                  {network.networkName} channels:
                </div>
                <ul
                  className={varClass({
                    'unclickable': submittingMappings,
                  })}
                >
                  {map(
                    connection?.connectionChannels?.sort(connectionSort),
                    (connectionChannel) => (
                      <li
                        key={connectionChannel.connectionChannelUuid}
                        className={varClass({
                          'active': isChannelSelected(
                            channel,
                            connectionChannel
                          ),
                        })}
                        onClick={() => {
                          toggleSelectedChannel(channel, connectionChannel)
                        }}
                      >
                        {toUnicode(
                          host(connectionChannel.connectionChannelUrl)
                        ) || connectionChannel.connectionChannelName}
                      </li>
                    )
                  )}
                </ul>
              </React.Fragment>
            ))}
          </div>
        )}

        {connectionsLoaded <= 0 && (
          <div className="connect-mapping-list loading">
            <div className="list-header">
              <div className="card card-logo borderless">
                <Logo
                  src={network.networkLogoUrl}
                  text={network.networkName}
                  width={74}
                  height={20}
                />
              </div>
              <div className="text-dark text-bolder">{network.networkName}</div>
            </div>
            {noConnections ? (
              <div className="text-center text-light text-smaller text-bolder p-y-3">
                No channels available
              </div>
            ) : (
              <div className="row row-center row-space-between p-y-3 p-x-4">
                <div className="text-light text-smaller">
                  Fetching channels...
                </div>
                <div className="loading-bar" />
              </div>
            )}
          </div>
        )}

        {(connectionsLoaded > 0 || noConnections) && (
          <div className="row row-center row-space-between m-t-8">
            <div>
              {submittingMappings ? (
                <div className="loader-sm" style={{ margin: 0 }} />
              ) : (
                <button className="btn btn-bordered" onClick={onSkip}>
                  Done
                </button>
              )}
            </div>
            {connectionsLoaded > 0 && (
              <div>
                <button
                  className="btn btn-dark"
                  disabled={!selectedChannelsCount && !submittingMappings}
                  onClick={submitMappings}
                >
                  Map {selectedChannelsCount}{' '}
                  {plural(selectedChannelsCount, 'channel')} to{' '}
                  {network.networkName}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export const ConnectFirstNetworkModal = (props) => {
  const { networks, channels, onConnect, onSkip, onDone } = props

  const { hideModal } = useModal()

  const [network, setNetwork] = useState(null)
  const [connectionUuid, setConnectionUuid] = useState('')
  const [mapped, setMapped] = useState(false)

  if (!network) {
    return (
      <NetworkList
        label="Connect to your first affiliate network."
        networks={networks}
        onSelect={setNetwork}
      />
    )
  }

  if (!connectionUuid) {
    return (
      <NetworkSignin
        network={network}
        goBack={() => setNetwork(null)}
        onDone={(connectionUuid) => {
          setConnectionUuid(connectionUuid)
          if (onConnect) {
            onConnect(network)
          }
        }}
      />
    )
  }

  if (!mapped) {
    return (
      <ChannelMappingList
        {...{ network, channels, connectionUuid }}
        onSkip={() => {
          setMapped(true)
          if (onSkip) {
            onSkip()
          }
        }}
        onDone={() => {
          setMapped(true)
          if (onDone) {
            onDone()
          }
        }}
      />
    )
  }

  return (
    <>
      <div className="card-header hidden" />
      <div className="card-body text-center">
        <div>
          <SVG src="/images/networks/illustration-success.svg" />
        </div>
        <div className="text-dark text-larger text-bolder m-t-40">
          Success {network.networkName} is connected
        </div>
        <div className="m-t-20">Congrats on connecting your first network</div>
        <div className="text-bolder m-t-20">
          We are now importing your campaigns and historical data, we will send
          you an email once this process is done.
        </div>
        <div className="m-t-30">
          <button
            className="btn btn-dark"
            onClick={() => {
              hideModal()
              navigateReload()
            }}
          >
            Let's go
          </button>
        </div>
      </div>
    </>
  )
}

export const ConnectNewNetworkModal = (props) => {
  const { networks, channels, onConnect, onSkip, onDone } = props

  const { hideModal } = useModal()

  const [network, setNetwork] = useState(null)
  const [connectionUuid, setConnectionUuid] = useState('')
  const [mapped, setMapped] = useState(false)

  if (!network) {
    return (
      <NetworkList
        label="Connect to an affiliate network."
        networks={networks}
        onSelect={setNetwork}
      />
    )
  }

  if (!connectionUuid) {
    return (
      <NetworkSignin
        network={network}
        goBack={() => setNetwork(null)}
        onDone={(connectionUuid) => {
          setConnectionUuid(connectionUuid)
          if (onConnect) {
            onConnect(network)
          }
        }}
      />
    )
  }

  if (!mapped) {
    return (
      <ChannelMappingList
        {...{ network, channels, connectionUuid }}
        onSkip={() => {
          setMapped(true)
          if (onSkip) {
            onSkip()
          }
        }}
        onDone={() => {
          setMapped(true)
          if (onDone) {
            onDone()
          }
        }}
      />
    )
  }

  return (
    <>
      <div className="card-header hidden" />
      <div className="card-body text-center">
        <div>
          <SVG src="/images/networks/illustration-success.svg" />
        </div>
        <div className="text-dark text-larger text-bolder m-t-40">
          Success {network.networkName} is connected
        </div>
        <div className="m-t-20">Congrats on connecting to a new network</div>
        <div className="text-bolder m-t-20">
          We are now importing your campaigns and historical data, we will send
          you an email once this process is done.
        </div>
        <div className="m-t-30">
          <button
            className="btn btn-dark"
            onClick={() => {
              hideModal()
              navigateReload()
            }}
          >
            Let's go
          </button>
        </div>
      </div>
    </>
  )
}

export const ConnectOpportunityNetworkModal = (props) => {
  const { network, channels, onConnect, onSkip, onDone } = props
  // props.opportunity (?)

  const { hideModal } = useModal()

  const [connectionUuid, setConnectionUuid] = useState('')
  const [mapped, setMapped] = useState(false)

  if (!connectionUuid) {
    return (
      <NetworkSignin
        network={network}
        onDone={(connectionUuid) => {
          setConnectionUuid(connectionUuid)
          if (onConnect) {
            onConnect(network)
          }
        }}
      />
    )
  }

  if (!mapped) {
    return (
      <ChannelMappingList
        {...{ network, channels, connectionUuid }}
        onSkip={() => {
          setMapped(true)
          if (onSkip) {
            onSkip()
          }
        }}
        onDone={() => {
          setMapped(true)
          if (onDone) {
            onDone()
          }
        }}
      />
    )
  }

  return (
    <>
      <div className="card-header hidden" />
      <div className="card-body text-center">
        <div>
          <SVG src="/images/networks/illustration-success.svg" />
        </div>
        <div className="text-dark text-larger text-bolder m-t-40">
          Success {network.networkName} is connected
        </div>
        <div className="text-bolder m-t-20">
          We are now importing your campaigns and historical data, we will send
          you an email once this process is done.
        </div>
        <div className="m-t-30">
          <button
            className="btn btn-dark"
            onClick={() => {
              hideModal()
              navigateReload()
            }}
          >
            Let's go
          </button>
        </div>
      </div>
    </>
  )
}

export const ConnectNetworkModal = (props) => {
  const { network, channels, onConnect, onSkip, onDone, submitConnection } =
    props

  const { hideModal } = useModal()

  const [connectionUuid, setConnectionUuid] = useState('')
  const [mapped, setMapped] = useState(false)

  if (!connectionUuid) {
    return (
      <NetworkSignin
        network={network}
        onDone={(connectionUuid) => {
          setConnectionUuid(connectionUuid)
          if (onConnect) {
            onConnect(network)
          }
        }}
        submitConnection={submitConnection}
      />
    )
  }

  if (!mapped) {
    return (
      <ChannelMappingList
        {...{ network, channels, connectionUuid }}
        onSkip={() => {
          setMapped(true)
          if (onSkip) {
            onSkip()
          }
        }}
        onDone={() => {
          setMapped(true)
          if (onDone) {
            onDone()
          }
        }}
      />
    )
  }

  return (
    <>
      <div className="card-header hidden" />
      <div className="card-body text-center">
        <div>
          <SVG src="/images/networks/illustration-success.svg" />
        </div>
        <div className="text-dark text-larger text-bolder m-t-40">
          Success {network.networkName} is connected
        </div>
        <div className="text-bolder m-t-20">
          We are now importing your campaigns and historical data, we will send
          you an email once this process is done.
        </div>
        <div className="m-t-30">
          <button
            className="btn btn-dark"
            onClick={() => {
              hideModal()
            }}
          >
            Let's go
          </button>
        </div>
      </div>
    </>
  )
}

export const EditConnectNetworkModal = (props) => {
  const { network, channels, connection: currentConnection, onDone } = props

  const { hideModal } = useModal()

  const {
    selectedChannels,
    setSelectedChannels,
    toggleSelectedChannel,
    isChannelSelected,
    selectedChannelsCount,
  } = useChannelSelection()

  const networkDetails = useApiGet<INetworksV1Network>(
    `/networks/v1/networks/${network.networkUuid}`
  )

  const connection = useMemo(
    () =>
      networkDetails?.connections?.find((connection) =>
        connection.connectionChannels
          .map((connectionChannel) => connectionChannel.connectionChannelUuid)
          .includes(
            currentConnection.connectionChanneluuid ||
              currentConnection.connectionChannelUuid
          )
      ),
    [networkDetails]
  )
  const connectionUuid = connection?.connectionUuid
  const noConnection = networkDetails && !connection

  const [openChannelUuid, setOpenChannelUuid] = useState('')

  useEffect(() => {
    if (networkDetails && connection) {
      const newSelectedChannels = {}
      const connectionChannel = connection.connectionChannels.find(
        (connectionChannel) =>
          isEqual(
            connectionChannel.connectionsMappings.map(
              (mapping) => mapping.connectionMappingUuid
            ),
            currentConnection.connectionChannelMappingUuids
          )
      )
      if (connectionChannel) {
        for (const connectionsMapping of connectionChannel.connectionsMappings) {
          newSelectedChannels[connectionsMapping.connectionMappingChannelUuid] =
            [connectionChannel.connectionChannelUuid]
        }
        setSelectedChannels(newSelectedChannels)
      }
    }
  }, [networkDetails, connection])

  const [submitMappings, submittingMappings] = useFormSubmit(async (values) => {
    const deletedChannels = {}
    for (const connectionChannel of connection.connectionChannels) {
      for (const mapping of connectionChannel.connectionsMappings) {
        if (
          selectedChannels[mapping.connectionMappingChannelUuid] &&
          !selectedChannels[mapping.connectionMappingChannelUuid].includes(
            connectionChannel.connectionChannelUuid
          )
        ) {
          deletedChannels[mapping.connectionMappingChannelUuid] =
            deletedChannels[mapping.connectionMappingChannelUuid] || []
          deletedChannels[mapping.connectionMappingChannelUuid].push(
            connectionChannel.connectionChannelUuid
          )
        }
      }
    }

    for (const channelUuid of Object.keys(deletedChannels)) {
      const connectionChannelUuids = deletedChannels[channelUuid]
      for (const connectionChannelUuid of connectionChannelUuids) {
        try {
          await deleteRequest(
            `/channels/v1/channel/${channelUuid}/connectionchannels/${connectionChannelUuid}`
          )
        } catch (error) {
          console.log(error)
        }
      }
    }

    for (const channelUuid of Object.keys(selectedChannels)) {
      const connectionChannelUuids = selectedChannels[channelUuid]
      for (const connectionChannelUuid of connectionChannelUuids) {
        try {
          await postRequest(
            `/channels/v1/channel/${channelUuid}/connectionchannels`,
            {
              connectionChannelUuid,
            }
          )
        } catch (error) {
          console.log(error)
        }
      }
    }

    hideModal()
    if (onDone) {
      onDone()
    }
  })

  if (connectionUuid) {
    const connectionChannels =
      connection?.connectionChannels?.sort(connectionSort) || []
    const tooManyChannels =
      channels.length > 10 && connectionChannels.length > 10

    return (
      <>
        <div className="card-header">
          <div className="card-title">Map affiliate channels</div>
        </div>
        <div className="card-body">
          <div className="m-b-30">
            By mapping the affiliate channels that matches your{' '}
            {network.networkName}, you are able to get in-depth insights on the
            traffic going out from this channel.
          </div>
          <div className="connect-mapping-list">
            {map(channels, (channel) => (
              <React.Fragment key={channel.channelUuid}>
                <div
                  className={
                    tooManyChannels ? 'list-header clickable' : 'list-header'
                  }
                  onClick={() => {
                    if (openChannelUuid === channel.channelUuid) {
                      setOpenChannelUuid('')
                    } else {
                      setOpenChannelUuid(channel.channelUuid)
                    }
                  }}
                >
                  <Logo
                    src={
                      channels.find(
                        (channelDetails) =>
                          channelDetails.channelUuid === channel.channelUuid
                      )?.channelFaviconUrl
                    }
                    letter={channel.channelDomain || channel.channelName || ''}
                    width={28}
                    height={28}
                    bordered={true}
                  />
                  <span className="text-dark text-big text-bolder m-l-10">
                    {channel.channelDomain}
                  </span>
                </div>
                {(!tooManyChannels ||
                  channel.channelUuid === openChannelUuid) && (
                  <>
                    <div className="text-light text-small text-bold m-4 m-b-10">
                      {network.networkName} channels:
                    </div>
                    <ul className={submittingMappings ? 'unclickable' : ''}>
                      {connectionChannels?.map((connectionChannel) => (
                        <li
                          key={connectionChannel.connectionChannelUuid}
                          className={
                            isChannelSelected(channel, connectionChannel)
                              ? 'active'
                              : ''
                          }
                          onClick={() =>
                            toggleSelectedChannel(channel, connectionChannel)
                          }
                        >
                          {host(connectionChannel.connectionChannelUrl) ||
                            connectionChannel.connectionChannelName}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="row row-center row-space-between m-t-8">
            <div>
              {submittingMappings ? (
                <div className="loader-sm" style={{ margin: 0 }} />
              ) : (
                <button
                  className="btn btn-bordered"
                  onClick={() => {
                    hideModal()
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
            <div>
              <button
                className="btn btn-dark"
                disabled={!selectedChannelsCount && !submittingMappings}
                onClick={submitMappings}
              >
                Map {selectedChannelsCount}{' '}
                {plural(selectedChannelsCount, 'channel')} to{' '}
                {network.networkName}
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Map affiliate channels</div>
      </div>
      <div className="card-body">
        <div className="connect-mapping-list">
          <div className="column flex-center flex-1 h-100">
            {noConnection ? (
              <div className="text-light">Connection not found</div>
            ) : (
              <div className="loading-bar" />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const RemoveConnectionModal = (props) => {
  const { connection, showSnackbar } = props

  const { hideModal } = useModal()

  const [agreed, setAgreed] = useState(false)

  const submit = async () => {
    const response = await deleteRequest(
      `/networks/v1/connections/${connection.connectionUuid}`
    )

    if (response) {
      if (response.code === 200) {
        hideModal()
        navigateReload() // Should redirect to /networks/ (?)
      } else {
        showSnackbar(responseError(response))
      }
    }
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Delete network</div>
      </div>
      <div className="card-body">
        <div className="notice-info m-b-5">
          <SVG src="/images/notice-info.svg" />
          You are about to delete a Network connection. This will cause all
          imported Campaigns from this Network to no longer be imported and used
          by Heylink Intelligent Tracking.
        </div>

        <div className="control control-checkbox m-b-7">
          <input
            type="checkbox"
            checked={agreed}
            onChange={(event) => setAgreed(event.target.checked)}
          />
          <label>
            Yes — I'm sure i want to delete this network connection.
          </label>
        </div>

        <div className="text-right">
          <button
            className="btn btn-primary"
            disabled={!agreed}
            onClick={submit}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  )
}

export const RemoveMappingModal = (props) => {
  const { channel, mapping, showSnackbar } = props

  const { hideModal } = useModal()

  const [agreed, setAgreed] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const submit = async () => {
    setSubmitting(true)
    const response = await deleteRequest(
      `/channels/v1/channel/${mapping.connectionMappingChannelUuid}/connectionchannels/${channel.connectionChannelUuid}`
    )

    if (response) {
      if (response.code === 200) {
        hideModal()
        navigateReload()
      } else {
        showSnackbar(responseError(response))
      }
    }
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Delete network</div>
      </div>
      <div className="card-body">
        <div className="notice-info m-b-5">
          <SVG src="/images/notice-info.svg" />
          You are about to delete a Network connection. This will cause all
          imported Campaigns from this Network to no longer be imported and used
          by Heylink Intelligent Tracking.
        </div>

        <div className="control control-checkbox m-b-7">
          <input
            type="checkbox"
            checked={agreed}
            onChange={(event) => setAgreed(event.target.checked)}
          />
          <label>
            Yes — I'm sure i want to delete this network connection.
          </label>
        </div>

        <div className="text-right vertical-middle">
          {submitting && (
            <span
              className="loader-sm inline-block m-r-4"
              style={{ margin: 0 }}
            />
          )}
          <button
            className="btn btn-primary"
            disabled={!agreed || submitting}
            onClick={submit}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  )
}
