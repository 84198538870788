import React, { useState } from 'react'
import { navigate } from 'gatsby'
import SVG from 'react-inlinesvg'
import { useForm } from 'react-hook-form'
import { navigateReload, currencies } from '../helpers'
import { useFormSubmit, useModal, copyToClipboard, responseError } from '..'
import { postRequest, putRequest, deleteRequest } from '../../../services'

const scriptHost = process.env.SCRIPT_HOST

export const NewChannelModal = (props) => {
  const { showSnackbar } = props

  const { hideModal } = useModal()

  const form = useForm()

  const [scriptUrl, setScriptUrl] = useState('')

  const [submitted, setSubmitted] = useState(false)
  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    if (scriptUrl) {
      return
    }

    const response = await postRequest('/channels/v1/channels', values)

    if (response) {
      if (response.code === 200) {
        setScriptUrl(response.data)
        setSubmitted(true)
      } else {
        showSnackbar(responseError(response), { type: 'alert' })
      }
    }
  })

  return (
    <>
      <div className="card-header">
        <div className="card-title">Add new site</div>
      </div>
      <div className="card-body">
        <ChannelForm
          {...{
            form,
            onSubmit,
            submitting,
            submitted,
            scriptUrl,
            showSnackbar,
            hideModal,
          }}
          submitText="Create site"
        />
      </div>
    </>
  )
}

export const EditChannelModal = (props) => {
  const { channel, showSnackbar } = props

  const { hideModal } = useModal()

  const form = useForm({
    defaultValues: {
      channelName: channel.channelName,
      channelType: channel.channelType,
      channelDomain: channel.channelDomain,
      channelCurrencyCode: channel.channelCurrencyCode || "",
    },
  })

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    const response = await putRequest(
      `/channels/v1/channel/${channel.channelUuid}`,
      values
    )

    if (response) {
      if (response.code === 200) {
        hideModal()
        navigateReload()
      } else {
        showSnackbar(responseError(response), { type: 'alert' })
      }
    }
  })

  return (
    <>
      <div className="card-header">
        <div className="card-title">Edit site</div>
      </div>
      <div className="card-body">
        <ChannelForm
          {...{
            form,
            onSubmit,
            submitting,
            showSnackbar,
            hideModal,
          }}
          submitText="Update site"
        />
      </div>
    </>
  )
}

const ChannelForm = (props) => {
  const {
    form,
    onSubmit,
    submitting,
    submitted,
    scriptUrl,
    showSnackbar,
    hideModal,
    submitText,
  } = props

  const { register, handleSubmit } = form

  const url = `<script src='${scriptHost}/${
    scriptUrl || ''
  }/script.js' defer></script>`

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {scriptUrl ? (
        <ChannelScript
          onDone={() => navigateReload()}
          {...{ url, showSnackbar, hideModal }}
        />
      ) : (
        <>
          <div className="row m-b-20">
            <div className="control flex-1">
              <label>Site name</label>
              <input
                type="text"
                disabled={submitted}
                {...register('channelName')}
              />
            </div>
            <div className="control control-select flex-1">
              <label>Type</label>
              <select disabled={submitted} {...register('channelType')}>
                <option hidden>Choose type</option>
                <option value="App">App</option>
                <option value="Newsletter">Newsletter</option>
                <option value="Website">Website</option>
                <option value="Social media">Social media</option>
              </select>
            </div>
          </div>
          <div className="row m-b-20">
            <div className="control flex-2">
              <label>URL</label>
              <input
                type="text"
                disabled={submitted}
                {...register('channelDomain')}
              />
            </div>
            <div className="control control-select flex-1">
              <label>Default currency</label>
              <select disabled={submitted} {...register('channelCurrencyCode')}>
                <option value="">Any currency</option>
                {currencies.map((currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="text-right">
            <button
              className="btn btn-dark"
              type="submit"
              disabled={submitting}
            >
              {submitText}
            </button>
          </div>
        </>
      )}
    </form>
  )
}

const ChannelScript = (props) => {
  const { url, showSnackbar, hideModal, onDone } = props

  return (
    <>
      <div className="text-dark text-bold m-b-2">
        Install this script on your website
      </div>
      <pre className="m-b-6">{url}</pre>
      <div className="m-b-6">
        <button
          className="btn btn-w-icon btn-md"
          type="button"
          onClick={() => {
            copyToClipboard(url)
            showSnackbar('Copied to clipboard')
          }}
        >
          <SVG src="/images/icon-copy.svg" />
          Copy
        </button>
      </div>
      <div className="text-right">
        <button
          className="btn btn-dark"
          type="button"
          onClick={() => {
            hideModal()
            if (onDone) {
              onDone()
            }
          }}
        >
          Done
        </button>
      </div>
    </>
  )
}

export const ScriptChannelModal = (props) => {
  const { channel, showSnackbar } = props

  const { hideModal } = useModal()

  const url = `<script src='${scriptHost}/${
    channel.channelUuid || ''
  }/script.js' defer></script>`

  return (
    <>
      <div className="card-header">
        <div className="card-title">{channel.channelName}</div>
      </div>
      <div className="card-body">
        <ChannelScript {...{ url, showSnackbar, hideModal }} />
      </div>
    </>
  )
}

export const RemoveChannelModal = (props) => {
  const { channel, showSnackbar } = props

  const { hideModal } = useModal()

  const [submitting, setSubmitting] = useState(false)
  const onSubmit = async () => {
    setSubmitting(true)
    const response = await deleteRequest(
      `/channels/v1/channel/${channel.channelUuid}`
    )
    setSubmitting(false)

    if (response) {
      if (response.code === 200) {
        hideModal()
        navigate('/sites')
      } else {
        showSnackbar(responseError(response), { type: 'alert' })
      }
    }
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Remove site</div>
      </div>
      <div className="card-body">
        Are you sure you want to remove site <b>{channel.channelName}</b>?
        <div className="m-t-5">
          <button
            className="btn btn-dark m-r-3"
            onClick={onSubmit}
            disabled={submitting}
          >
            Remove site
          </button>
          <button
            className="btn btn-link text-dark"
            onClick={hideModal}
            disabled={submitting}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}
