import React, { useState, useRef, useContext } from 'react'
import SVG from 'react-inlinesvg'
import { Logo } from '../elements'
import { applicationContext } from '../contexts'
import { useOutsideClick, useApiGet } from '../hooks'
import { varClass, map, isBlank } from '../helpers'

type TChannel = {
  channelUuid: string
  channelName: string
}
interface ChannelPickerState {
  selectedChannel: TChannel | undefined
  setSelectedChannel: (newChannel: TChannel | undefined) => void
}

export const useChannelPickerState = () => {
  const { channelPickerState } = useContext<any>(applicationContext)
  return channelPickerState
}

export const newChannelPickerState = () => {
  const [selectedChannel, setSelectedChannel] = useState(undefined)
  return { selectedChannel, setSelectedChannel }
}

export const ChannelPicker = (props) => {
  const [open, setOpen] = useState(false)
  const ref = useRef()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const channels = useApiGet('/channels/v1/list')

  const { selectedChannel, setSelectedChannel } = useChannelPickerState()

  return (
    <div className="channel-picker" ref={ref}>
      <div
        className={varClass({
          'channel-picker-control': true,
          'open': open,
        })}
        onClick={() => setOpen(!open)}
      >
        <div className="input">
          <div>
            <SVG src="/images/aside-channels.svg" className="icon" />
            <span>
              {selectedChannel ? selectedChannel.channelDomain : 'All sites'}
            </span>
          </div>
          <div>
            <SVG src="/images/chevron-right.svg" className="chevron" />
          </div>
        </div>
      </div>
      <ul
        className={varClass({
          'channel-picker-list': true,
          'shown': open,
        })}
      >
        <li
          className={varClass({
            'disabled': isBlank(selectedChannel),
          })}
          onClick={() => {
            if (!isBlank(selectedChannel)) {
              setOpen(false)
              setSelectedChannel(undefined)
            }
          }}
        >
          <SVG src="/images/aside-channels.svg" className="icon" />
          All sites
        </li>
        {map(channels, (channel) => (
          <li
            key={channel.channelUuid}
            onClick={() => {
              setOpen(false)
              setSelectedChannel(channel)
            }}
          >
            <Logo src={channel.channelFaviconUrl} width={18} height={18} />
            {channel.channelDomain}
          </li>
        ))}
      </ul>
    </div>
  )
}
