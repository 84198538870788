import React, { useEffect, createContext, useContext, useRef } from 'react'
import { createPortal } from 'react-dom'
import SVG from 'react-inlinesvg'
import { useModalContext } from '../hooks'
import { varClass } from '../helpers'

export const modalContext = createContext({})

export const ModalContextProvider = (props) => {
  const value = useModalContext()

  return (
    <modalContext.Provider value={value}>
      <Modal />
      {props.children}
    </modalContext.Provider>
  )
}

const Modal = () => {
  const context = useContext<any>(modalContext)

  const open = context.modalOpen

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [open])

  const clickAway = useRef({ awayDown: false }).current

  return createPortal(
    <div
      className={varClass({
        'modal': true,
        'open': open,
      })}
      onMouseDown={(event: any) => {
        if (
          event.target.classList.contains('modal') &&
          event.target.classList.contains('open')
        ) {
          clickAway.awayDown = true
        }
      }}
      onMouseUp={(event: any) => {
        if (
          event.target.classList.contains('modal') &&
          event.target.classList.contains('open') &&
          clickAway.awayDown
        ) {
          context.hideModal()
        }
        delete clickAway.awayDown
      }}
    >
      <div
        className={varClass({
          'card': true,
          [context.modalOptions?.cardClassName || '']:
            context.modalOptions?.cardClassName,
        })}
      >
        <button
          className="modal-close"
          onClick={() => {
            context.hideModal()
          }}
        >
          <SVG src="/images/times.svg" />
        </button>
        {context.modalContent}
      </div>
    </div>,
    document.querySelector('body')
  )
}
