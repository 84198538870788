import React from 'react'
import { CartesianGrid, AreaChart, Area, ResponsiveContainer } from 'recharts'
import { useWindowSize } from '../hooks'

export const MiniAreaChart = (props) => {
  const { title, data: originalData, color, height, chartType } = props

  const data = originalData.map((item, index) => ({
    value: item,
  }))

  const windowSize = useWindowSize()

  const svgId = (prefix) =>
    `${prefix}-${title.toLowerCase().replace(/\W/g, '')}`

  return (
    <ResponsiveContainer
      key={windowSize.width}
      width="99.9%"
      height={height}
      className="mini-area-chart"
    >
      <AreaChart data={data}>
        <defs>
          <linearGradient id={svgId('stroke')} x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor={color} stopOpacity={0.5} />
            <stop offset="100%" stopColor={color} stopOpacity={1} />
          </linearGradient>
          <linearGradient id={svgId('fill')} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={color} stopOpacity={0.1} />
            <stop offset="100%" stopColor="#ffffff" stopOpacity={0.2} />
          </linearGradient>
        </defs>

        <CartesianGrid
          horizontal={false}
          strokeDasharray="6"
          stroke="#EDEFF2"
        />

        <Area
          type={chartType || 'linear'}
          dataKey="value"
          stroke={`url(#${svgId('stroke')})`}
          strokeWidth={2}
          fill={`url(#${svgId('fill')})`}
          fillOpacity={1}
          dot={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}
