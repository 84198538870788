import React, { useState, useEffect, useRef } from 'react'
import SVG from 'react-inlinesvg'
import { useOutsideClick } from '../hooks'
import { navigateTo, isBlank, varClass, map } from '../helpers'
import { getRequest } from '../../../services/api'

export const TableInfo = (props) => {
  return (
    <div
      className={varClass({
        'table-info-container': true,
        [props.wrapperClassName]: !!props.wrapperClassName,
      })}
    >
      <div
        className={varClass({
          'table-info row row-center row-space-between p-x-6 m-b-4': true,
          [props.className]: !!props.className,
        })}
      >
        {props.children}
      </div>
    </div>
  )
}

export const TableHead = (props) => {
  const { value, label, className, align, desc, sort, dir, toggleSort, style } =
    props

  const textAlign =
    align === 'right' ? 'right' : align === 'center' ? 'center' : 'left'

  return (
    <th
      className={varClass({
        [className]: !!className,
        [`text-${textAlign}`]: true,
        'sort-highlight': sort === value,
      })}
      onClick={toggleSort(value, desc ? 'desc' : 'asc')}
      style={{
        ...(style || {}),
      }}
    >
      <span className="column-label text-nowrap">
        {textAlign === 'right' && (
          <TableHeadSortIcon className="m-r-1" {...{ value, sort, dir }} />
        )}
        {label}
        {textAlign !== 'right' && (
          <TableHeadSortIcon className="m-l-1" {...{ value, sort, dir }} />
        )}
      </span>
    </th>
  )
}

const TableHeadSortIcon = (props) => {
  const { className, value, sort, dir } = props

  if (sort === value) {
    if (dir === 'desc') {
      return <SVG src="/images/insights/caret-down.svg" className={className} />
    } else {
      return <SVG src="/images/insights/caret-up.svg" className={className} />
    }
  } else {
    return <SVG src="/images/insights/caret.svg" className={className} />
  }
}

export const TableColumns = (props) => {
  const {
    storageKey,
    columns,
    setColumns,
    availableColumns,
    columnLabels,
    className,
  } = props

  const [open, setOpen] = useState(false)

  const ref = useRef<any>()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  return (
    <div className="table-columns" ref={ref}>
      <button
        className={varClass({
          'table-columns-select link action': true,
          [className]: !!className,
        })}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <SVG src="/images/insights/icon-columns.svg" className="m-r-10" />
        Edit columns
        <SVG src="/images/chevron-down.svg" className="m-l-4 m-r--15" />
      </button>

      <div
        className={varClass({
          'table-columns-menu': true,
          'shown': open,
        })}
      >
        <ul>
          {map(availableColumns, (column, index) => {
            if (index === 0) {
              return null
            } else {
              return (
                <li
                  key={column}
                  className={varClass({
                    'selected': columns.indexOf(column) !== -1,
                  })}
                  onClick={() => {
                    const newColumns = [...columns]
                    const columnIndex = columns.indexOf(column)
                    if (columnIndex === -1) {
                      newColumns.splice(index, 0, column)
                    } else {
                      newColumns.splice(columnIndex, 1)
                    }
                    setColumns(newColumns)
                    localStorage.setItem(storageKey, newColumns.join(','))
                  }}
                >
                  {columnLabels[column]}
                </li>
              )
            }
          })}
        </ul>
      </div>
    </div>
  )
}

export const useTableEmpty = (data: any[]) => useState(isBlank(data))

export const TableEmpty = (props) => {
  const {
    icon,
    title,
    subtitle,
    buttons,
    showArrow,
    style,
    setIsEmpty,
    dataTitle,
    dataSubtitle,
    dataShowArrow,
  } = props

  const [show, setShow] = useState(false)
  const [showData, setShowData] = useState(false)

  useEffect(() => {
    const callback = async () => {
      if (!buttons) {
        setShow(true)
        return
      }

      const checkChannels = Object.keys(buttons).some((btnTarget: string) =>
        btnTarget.includes('channels')
      )
      const checkNetworks = Object.keys(buttons).some((btnTarget: string) =>
        btnTarget.includes('networks')
      )

      if (checkChannels) {
        const channels = await getRequest('/channels/v1/list')
        if (isBlank(channels.data)) {
          return setShow(true)
        }
      }

      if (checkNetworks) {
        const networks = await getRequest('/networks/v1/connections')
        if (isBlank(networks.data)) {
          return setShow(true)
        }
      }

      if (checkChannels || checkNetworks) {
        if (dataTitle || dataSubtitle || dataShowArrow) {
          setShow(true)
          setShowData(true)
        } else if (setIsEmpty) {
          setIsEmpty(false)
        }
        return
      }

      setShow(true)
    }
    callback()
  }, [])

  if (!show) return null

  return (
    <div className="table-empty" style={{ ...(style || {}) }}>
      {((!showData && showArrow) || (showData && dataShowArrow)) && (
        <SVG src="/images/empty-action.svg" className="table-empty-action" />
      )}
      <div className="column column-fill column-center column-middle text-center">
        {icon && <SVG src={`/images/empty-${icon}.svg`} className="m-b-5" />}

        {!showData && title && (
          <div
            className="text-dark text-bigger text-bolder m-b-10"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {showData && dataTitle && (
          <div
            className="text-dark text-bigger text-bolder m-b-10"
            dangerouslySetInnerHTML={{ __html: dataTitle }}
          />
        )}

        {!showData && subtitle && (
          <div
            className="text-bold m-b-40"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
        {showData && dataSubtitle && (
          <div
            className="text-bold m-b-40"
            dangerouslySetInnerHTML={{ __html: dataSubtitle }}
          />
        )}

        {!isBlank(buttons) && !showData && (
          <div className="row row-narrow">
            {map(buttons, (btnTarget, btnTitle) => (
              <button
                key={btnTarget}
                className="btn btn-dark"
                onClick={(event) => {
                  navigateTo(event, btnTarget)
                }}
              >
                {btnTitle}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
