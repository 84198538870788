import { useState, useContext } from 'react'
import { drawerContext } from '../contexts'

type TDrawer = {
  drawerOpen: boolean
  drawerContent: any
  drawerOptions: any
  showDrawer: (content: any, options?: any) => void
  hideDrawer: () => void
}
export const useDrawer = (): TDrawer => useContext(drawerContext) as TDrawer

export const useDrawerContext = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerContent, setDrawerContent] = useState(undefined)
  const [drawerOptions, setDrawerOptions] = useState({})

  const showDrawer = (newContent, newOptions = undefined) => {
    setDrawerOpen(!drawerOpen)
    if (newOptions) {
      setDrawerOptions(newOptions)
    } else if (!drawerOpen) {
      setDrawerOptions({})
    }
    if (newContent) {
      setDrawerContent(newContent)
    }
  }

  const hideDrawer = () => showDrawer(undefined)

  return { drawerOpen, drawerContent, drawerOptions, showDrawer, hideDrawer }
}
