import React, { createContext, useState, useEffect } from 'react'
import { newDatePickerState, newChannelPickerState } from '../elements'

export const applicationContext = createContext({})

export const ApplicationContextProvider = (props) => {
  const path = window.location.pathname
  const hash = window.location.hash.substr(1)

  const [headerTitle, setHeaderTitle] = useState(null)
  const [headerContent, setHeaderContent] = useState(null)
  const [headerPathname, setHeaderPathname] = useState(null)
  const [subheaderContent, setSubheaderContent] = useState(null)
  const [subheaderPathname, setSubheaderPathname] = useState(null)
  const [subheaderValue, setSubheaderValueState] = useState(null)

  const setSubheaderValue = (newValue: string) => {
    setSubheaderValueState(newValue)
    if (newValue) {
      window.history.pushState(
        { subheaderValue: newValue },
        document.title,
        `#${newValue}`
      )
    } else {
      window.history.replaceState(null, document.title, ' ')
    }
  }

  useEffect(() => {
    if (subheaderValue === null) {
      setSubheaderValue(hash)
    } else if (subheaderPathname !== path) {
      setSubheaderValue('')
    }
  }, [path, hash])

  useEffect(() => {
    const onPopState = (event) => {
      if (event.state?.subheaderValue) {
        setSubheaderValueState(event.state.subheaderValue)
      }
    }
    addEventListener('popstate', onPopState)
    return () => {
      removeEventListener('popstate', onPopState)
    }
  })

  const datePickerState = newDatePickerState()
  const channelPickerState = newChannelPickerState()

  const value = {
    headerTitle,
    setHeaderTitle,
    headerContent,
    setHeaderContent,
    headerPathname,
    setHeaderPathname,
    subheaderContent,
    setSubheaderContent,
    subheaderPathname,
    setSubheaderPathname,
    subheaderValue,
    setSubheaderValue,
    datePickerState,
    channelPickerState,
  }

  return (
    <applicationContext.Provider value={value}>
      {props.children}
    </applicationContext.Provider>
  )
}
